import React, { useCallback, useEffect, useState } from 'react';
import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import type { BlockAttributes } from '@quillforms/types';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '@quillforms/renderer-core/build-style/style.css';
import { createReference } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '@/components/CustomRegisterForm';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import '../../blocks/phoneblock';
import '../../blocks/USMailingAddress';
import { parseQuillFormAnswers } from '../../blocks/ParseQuillFormAnswers';
import classes from '../TestFormPage.module.css';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
import { getConfig } from '../../config';
import { useNavigate, useSearchParams } from 'react-router-dom';

import heroImage from '@/img/profile/ramona-hero.webp';
import startImage from '@/img/profile/entering-health-profile-ramona-ai.webp';
import comparisonImage from '@/img/profile/ramona-wellness-coaching-comparison.webp';
import { useMatomo } from '@/common/useMatomo';

import { Box, Grid } from '@mantine/core';


interface SubmissionDispatchers {
  completeForm: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSubmissionErr: (error: string) => void;
}

type Choice = {
  value: string;
  label: string;
};

type BeforeGoingNextParams = {
  setIsFieldValid: (id: string, flag: boolean) => void;
  setFieldValidationErr: (id: string, err: string) => void;
  setIsPending: (flag: boolean) => void;
  setIsCurrentBlockSafeToSwipe: (flag: boolean) => void;
  goToBlock: (id: string) => void;
  goNext: () => void;
  currentBlockId: string;
  answers: Record<string, any>;
};

function AccountCreationForm({
  onAccountCreated,
}: {
  onAccountCreated: () => void;
}): JSX.Element {
  const [error, setError] = useState('');
  const medplum = useMedplum();
  const config = getConfig();

  const handleRegister = () => {
    onAccountCreated();  
  };

  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => handleRegister()}
          >
            <h3>Let's Create Your Account</h3>
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}

export function BellyFatSurveyPage(): JSX.Element {
  const medplum = useMedplum();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { trackFormEvent } = useMatomo();

  const [surveyData, setSurveyData] = useState<Record<string, any> | null>(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const primaryMotivation = useFieldAnswer('primary_motivation') as string | undefined;
  const motivationLevel = useFieldAnswer('motivation_level') as number | undefined;
  const contributingFactors = useFieldAnswer('contributing_factors') as string[] | undefined;
  const pastMethods = useFieldAnswer('past_methods') as string[] | undefined;
  const healthConditions = useFieldAnswer('health_conditions') as string[] | undefined;
  const preferredActivities = useFieldAnswer('preferred_activities') as string[] | undefined;
  const currentDiet = useFieldAnswer('current_diet') as string[] | undefined;
  const nextStep = useFieldAnswer('next_step') as string | undefined;
  const potentialObstacles = useFieldAnswer('potential_obstacles') as string[] | undefined;

  useEffect(() => {
    if (queryString && medplum.getProfile()) {
      const patient = medplum.getProfile() as Patient;
      const updatedPatient = {
        ...patient,
        extension: [
          ...(patient.extension || []),
          {
            url: 'http://ramona.care/fhir/StructureDefinition/query-string',
            valueString: queryString,
          },
        ],
      };
      medplum
        .updateResource(updatedPatient)
        .then(() => {
          console.log('Patient resource updated successfully');
        })
        .catch((error) => {
          console.error('Error updating patient resource:', error);
        });
    }
  }, [queryString, medplum]);

  const handleSubmit = useCallback(
    (data: Record<string, any>, dispatchers: SubmissionDispatchers) => {
      const { completeForm, setIsSubmitting, setSubmissionErr } = dispatchers;

      console.log('Submitted answers:', data);

      const profile = medplum.getProfile();

      if (profile) {
        const patientReference = createReference(profile as Patient);
        const itemList = parseQuillFormAnswers(data.answers.answers.answers, formObj.blocks);
        const newResponse: QuestionnaireResponse = {
          resourceType: 'QuestionnaireResponse',
          status: 'completed',
          item: itemList,
          questionnaire: `Questionnaire/${config.onboardingId}`,
          subject: patientReference,
          source: patientReference,
          authored: new Date().toISOString(),
          author: patientReference,
        };

        setIsSubmitting(true);
        medplum
          .createResource(newResponse)
          .then((response) => {
            console.log('Created QuestionnaireResponse:', response);
            setIsSubmitting(false);
            completeForm();
            navigate('/select-plan');
          })
          .catch((error) => {
            console.error('Error creating QuestionnaireResponse:', error);
            setSubmissionErr('An error occurred while submitting the form.');
            setIsSubmitting(false);
          });
      } else {
        setSurveyData(data);
        setIsCreatingAccount(true);
      }
    },
    [medplum, config.onboardingId, navigate]
  );

  const handleAccountCreated = useCallback(() => {
    const profile = medplum.getProfile() as Patient;
    if (profile && surveyData) {
      const patientReference = createReference(profile);
      const itemList = parseQuillFormAnswers(surveyData.answers, formObj.blocks);
      itemList.push({
        linkId: "primary_goal",
        text: "What's your primary health goal right now?",
        answer: [
          {
            valueString: "Reduce belly fat"
          }
        ]
      });
      const newResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        item: itemList,
        questionnaire: `Questionnaire/${config.onboardingId}`,
        subject: patientReference,
        source: patientReference,
        authored: new Date().toISOString(),
        author: patientReference,
      };

      medplum
        .createResource(newResponse)
        .then((response) => {
          console.log('Created QuestionnaireResponse:', response);
          setIsCreatingAccount(false);
          navigate('/select-plan');
        })
        .catch((error) => {
          console.error('Error creating QuestionnaireResponse:', error);
        });
    }
  }, [medplum, surveyData, config.onboardingId, navigate]);

  const beforeGoingNext = ({
    setIsFieldValid,
    setFieldValidationErr,
    setIsPending,
    setIsCurrentBlockSafeToSwipe,
    goToBlock,
    goNext,
    currentBlockId,
    answers,
  }: BeforeGoingNextParams): void => {

    // Track the new block view
    const currentBlock = formObj.blocks.find(block => block.id === currentBlockId);
    if (currentBlock) {
      trackFormEvent(
        'Form Navigation',
        'View Block',
        `${currentBlock.name} - ${currentBlockId}`
      );
    }

    const setFieldValid = (): void => {
      setIsFieldValid(currentBlockId, true);
      setFieldValidationErr(currentBlockId, '');
      setIsCurrentBlockSafeToSwipe(true);
    };

    const setFieldInvalid = (errorMessage: string): void => {
      setIsFieldValid(currentBlockId, false);
      setFieldValidationErr(currentBlockId, errorMessage);
      setIsCurrentBlockSafeToSwipe(false);
    };

    switch (currentBlockId) {
      case 'age':
        const age = answers[currentBlockId].value as number;
        if (age < 18) {
          setFieldInvalid('You must be 18 or older to use this service.');
        } else {
          setFieldValid();
          goNext();
        }
        break;

      default:
        setFieldValid();
        goNext();
    }
  };

  const formObj: FormObj = {
    blocks: [
      {
        name: 'welcome-screen',
        id: 'welcome',
        attributes: {
          label: 'Welcome to Your AI Health Coach Journey for Belly Fat Loss!',
          description:
            "Hi there! I'm Ramona, your AI health coach specializing in belly fat loss. I'm excited to start this journey with you! This quick survey will help me understand your goals and prepare for our first chat. It should only take about 5-7 minutes to complete.",
          buttonText: "Let's Get Started",
          attachment: {
            type: 'image',
            url: heroImage,
          },
          layout: 'float-right',
        },
      },
      {
        name: 'short-text',
        id: 'preferred_name',
        attributes: {
          label: "What's your preferred name?",
          required: true,
        },
      },
      {
        name: 'multiple-choice',
        id: 'primary_motivation',
        attributes: {
          label: "What's your primary motivation for wanting to lose belly fat?",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'improve_health', label: '🏋️ To improve my overall health' },
            { value: 'confidence', label: '🪞 To feel more confident about my appearance' },
            { value: 'reduce_risks', label: '🫀 To reduce health risks (e.g., diabetes, heart disease)' },
            { value: 'fit_clothes', label: '🩱 To fit into my favorite clothes better' },
            { value: 'athletic_performance', label: '🚴 To improve my athletic performance' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(primaryMotivation?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_primary_motivation',
              attributes: {
                label: 'Please specify your other primary motivation:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'intro_value',
          attributes: {
            label: "With Ramona, you take your first personalized step towards reducing belly fat on day one.",
            description: "Personalized digital health interventions significantly outperform non-personalized approaches in improving physical activity and diet*\n\n<i>*Journal Medical Internet Research, 2021</i>",
            layout: 'float-right',
            attachment: {
              type: 'image',
              url: comparisonImage
            }
          },
        },
      {
        name: 'slider',
        id: 'motivation_level',
        attributes: {
          label: 'On a scale of 1-10, how motivated are you to lose belly fat?',
          description: '1: Not at all, 10: Extremely',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'statement',
        id: 'goal_specific_questions',
        attributes: {
          label:
            motivationLevel === undefined || motivationLevel < 5
              ? "We're Just Getting Started, {{field:preferred_name}}!"
              : 'Great Start, {{field:preferred_name}}!',
          description:
            motivationLevel === undefined || motivationLevel < 5
              ? "While you may not have the motivation now, making progress is possible. No matter where you're starting from we can define a first step that you can achieve in days."
              : "I'm excited to help figure out your first goal. Let's continue to define a first step that you can achieve in days.",
          attachment: {
            type: 'image',
            url: startImage,
          },
          layout: 'split-right',
        },
      },
      {
        name: 'multiple-choice',
        id: 'belly_fat_situation',
        attributes: {
          label: 'How would you describe your current belly fat situation?',
          required: true,
          choices: [
            { value: 'small', label: 'I have a small amount of belly fat I\'d like to lose' },
            { value: 'moderate', label: 'I have a moderate amount of belly fat to lose' },
            { value: 'significant', label: 'I have a significant amount of belly fat to lose' },
            { value: 'unsure', label: 'I\'m not sure, but I want a flatter stomach' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'contributing_factors',
        attributes: {
          label: 'Have you noticed any specific factors that seem to contribute to your belly fat?',
          description: 'Select all that apply',
          multiple: true,
          choices: [
            { value: 'poor_diet', label: 'Poor diet choices' },
            { value: 'lack_exercise', label: 'Lack of exercise' },
            { value: 'stress', label: 'Stress' },
            { value: 'lack_sleep', label: 'Lack of sleep' },
            { value: 'hormonal', label: 'Hormonal changes' },
            { value: 'genetics', label: 'Genetics' },
            { value: 'alcohol', label: 'Alcohol consumption' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(contributingFactors?.includes('other')
      ? [
          {
            name: 'short-text',
            id: 'other_contributing_factor',
            attributes: {
              label: 'Please specify the other contributing factor:',
              required: true,
            },
          },
        ]
      : []),
      {
        name: 'multiple-choice',
        id: 'past_methods',
        attributes: {
          label: 'Have you tried any specific methods to lose belly fat in the past?',
          description: 'Select all that apply',
          multiple: true,
          choices: [
            { value: 'ab_exercises', label: 'Targeted ab exercises' },
            { value: 'cardio', label: 'Cardio workouts' },
            { value: 'calorie_restriction', label: 'Calorie restriction' },
            { value: 'low_carb', label: 'Low-carb diet' },
            { value: 'intermittent_fasting', label: 'Intermittent fasting' },
            { value: 'supplements', label: 'Specific "fat-burning" supplements' },
            { value: 'none', label: 'None of the above' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(pastMethods?.includes('other')
      ? [
          {
            name: 'short-text',
            id: 'other_past_method',
            attributes: {
              label: "Please specify the other method you've tried:",
              required: true,
            },
          },
        ]
      : []),
      {
        name: 'statement',
        id: 'vitals_intro',
        attributes: {
          label: pastMethods?.includes('none') ? 
            "No better time to take a first step!" : 
            "It's great that you've taken initiative, {{field:preferred_name}}!",

          description: "We will explore some of those mentioned methods and more to address your belly fat. Next, let's get some basic details."
        },
      },
      {
        name: 'multiple-choice',
        id: 'activity_level',
        attributes: {
          label: 'How would you describe your current activity level?',
          required: true,
          choices: [
            { value: 'sedentary', label: 'Sedentary (little to no exercise)' },
            { value: 'lightly_active', label: 'Lightly active (light exercise 1-3 days/week)' },
            { value: 'moderately_active', label: 'Moderately active (moderate exercise 3-5 days/week)' },
            { value: 'very_active', label: 'Very active (hard exercise 6-7 days/week)' },
          ] as Choice[],
        },
      },
      {
        name: 'group',
        id: 'height',
        attributes: {
          label: "What's your height? (in feet/inches)",
          description: 'Height is used to calculate health metrics and personalize fitness recommendations.',
        },
        innerBlocks: [
          {
            name: 'number',
            id: 'height-feet',
            attributes: {
              required: true,
              label: 'Feet',
              setMin: true,
              min: 3,
              setMax: true,
              max: 8,
            },
          },
          {
            name: 'number',
            id: 'height-inches',
            attributes: {
              required: true,
              label: 'Inches',
              setMin: true,
              min: 0,
              setMax: true,
              max: 11,
            },
          },
        ],
      },
      {
        name: 'number',
        id: 'weight',
        attributes: {
          label: "What's your current weight? (in pounds)",
          description: 'Weight helps us track progress and customize nutrition and exercise plans to your needs.',
          required: true,
          setMin: true,
          min: 50,
          setMax: true,
          max: 1000,
        },
      },
      {
        name: 'number',
        id: 'waist_circumference',
        attributes: {
          label: "If you know it, what's your current waist circumference? (in inches)",
          description: "This helps us track your progress specifically for belly fat loss.",
          required: false,
          setMin: true,
          min: 20,
          setMax: true,
          max: 80,
        },
      },
      {
        name: 'multiple-choice',
        id: 'health_conditions',
        attributes: {
          label: 'Do you have any health conditions we should be aware of?',
          description:
            "Knowing about any health conditions ensures our recommendations are safe and appropriate for your specific situation. Remember, we're not offering medical advice or replacing your healthcare provider – always consult with them about your health concerns.",
          multiple: true,
          required: true,
          choices: [
            { value: 'heart_condition', label: 'Heart condition' },
            { value: 'high_blood_pressure', label: 'High blood pressure' },
            { value: 'respiratory_condition', label: 'Respiratory condition (e.g., asthma, COPD)' },
            { value: 'diabetes', label: 'Diabetes' },
            { value: 'pregnancy', label: 'Pregnancy' },
            { value: 'allergies', label: 'Severe allergies or food intolerances' },
            { value: 'recent_surgery', label: 'Recent surgery or injury' },
            { value: 'mobility_condition', label: 'Condition affecting mobility' },
            { value: 'mental_health', label: 'Mental health condition' },
            { value: 'other', label: 'Other condition requiring medical supervision' },
            { value: 'none', label: 'None of the above' },
          ] as Choice[],
        },
      },
      ...(healthConditions?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_health_condition',
              attributes: {
                label: 'Please specify your other health condition:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'whole_health_intro',
          attributes: {
            label: "Looking at the whole picture",
            description: "We're making great progress, {{field:preferred_name}}. Let's cover some early details on your stress, sleep, nutrition, and exercise.",
          },
        },
      {
        name: 'slider',
        id: 'stress_level',
        attributes: {
          label: 'How would you rate your current stress levels?',
          description: '1: Very low, 10: Very high',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'multiple-choice',
        id: 'sleep_duration',
        attributes: {
          label: 'On average, how many hours of sleep do you get per night?',
          required: true,
          choices: [
            { value: 'less_than_5', label: 'Less than 5 hours' },
            { value: '5-6', label: '5-6 hours' },
            { value: '6-7', label: '6-7 hours' },
            { value: '7-8', label: '7-8 hours' },
            { value: 'more_than_8', label: 'More than 8 hours' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'alcohol_consumption',
        attributes: {
          label: 'How many alcoholic drinks do you typically consume per week?',
          required: true,
          choices: [
            { value: 'none', label: 'None' },
            { value: '1-3', label: '1-3 drinks' },
            { value: '4-7', label: '4-7 drinks' },
            { value: '8-14', label: '8-14 drinks' },
            { value: '15+', label: '15+ drinks' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'current_diet',
        attributes: {
          label: 'How would you describe your current diet?',
          required: true,
          choices: [
            { value: 'no_restrictions', label: 'No specific diet or restrictions' },
            { value: 'trying_healthy', label: 'Trying to eat healthily but not following a specific plan' },
            { value: 'specific_plan', label: 'Following a specific diet plan' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(currentDiet?.includes('specific_plan')
        ? [
            {
              name: 'short-text',
              id: 'other_current_diet',
              attributes: {
                label: "Glad to hear you are already on a program. Please share more about the specific plan.",
                required: true,
              },
            },
          ]
        : []),
        ...(currentDiet?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_current_diet',
              attributes: {
                label: 'Please share more about your current diet:',
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'multiple-choice',
        id: 'preferred_activities',
        attributes: {
          label: 'What types of physical activities do you enjoy or are interested in trying?',
          description: 'Select all that apply',
          multiple: true,
          choices: [
            { value: 'walking_jogging', label: 'Walking/Jogging' },
            { value: 'cycling', label: 'Cycling' },
            { value: 'swimming', label: 'Swimming' },
            { value: 'weight_training', label: 'Weight training' },
            { value: 'hiit', label: 'High-Intensity Interval Training (HIIT)' },
            { value: 'yoga_pilates', label: 'Yoga/Pilates' },
            { value: 'group_fitness', label: 'Group fitness classes' },
            { value: 'team_sports', label: 'Team sports' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(preferredActivities?.includes('other')
      ? [
          {
            name: 'short-text',
            id: 'other_preferred_activity',
            attributes: {
              label: 'Please specify your other preferred physical activity:',
              required: true,
            },
          },
        ]
      : []),
      {
        name: 'multiple-choice',
        id: 'exercise_commitment',
        attributes: {
          label: 'How many days per week can you commit to exercise?',
          required: true,
          choices: [
            { value: '1-2', label: '1-2 days' },
            { value: '3-4', label: '3-4 days' },
            { value: '5+', label: '5+ days' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'meals_per_day',
        attributes: {
          label: 'How many meals do you typically eat per day?',
          required: true,
          choices: [
            { value: '1-2', label: '1-2 meals' },
            { value: '3', label: '3 meals' },
            { value: '4-5', label: '4-5 meals' },
            { value: '6+', label: '6+ meals (including snacks)' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'eating_out_frequency',
        attributes: {
          label: 'How often do you eat out or order takeout?',
          required: true,
          choices: [
            { value: 'rarely', label: 'Rarely (0-1 times per week)' },
            { value: 'sometimes', label: 'Sometimes (2-3 times per week)' },
            { value: 'often', label: 'Often (4-5 times per week)' },
            { value: 'very_frequently', label: 'Very frequently (6+ times per week)' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'eating_challenge',
        attributes: {
          label: "What's your biggest challenge when it comes to eating healthy?",
          required: true,
          choices: [
            { value: 'portions', label: 'Controlling portions' },
            { value: 'cravings', label: 'Resisting cravings for unhealthy foods' },
            { value: 'time', label: 'Finding time to prepare healthy meals' },
            { value: 'knowledge', label: 'Knowing what foods to eat for belly fat loss' },
            { value: 'emotional_eating', label: 'Emotional eating' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'communication_style',
        attributes: {
          label: 'How would you like me to communicate with you?',
          required: true,
          choices: [
            { value: 'motivational', label: 'Motivational and encouraging' },
            { value: 'factual', label: 'Factual and informative' },
            { value: 'nurturing', label: 'Nurturing and supportive' },
            { value: 'direct', label: 'Direct and to-the-point' },
            { value: 'mix', label: 'Mix of styles' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'next_step',
        attributes: {
          label: "What's one small step you feel ready to take towards losing belly fat this week?",
          required: true,
          choices: [
            { value: 'track_food', label: 'Track my food intake for three days this week' },
            { value: 'water_replace', label: 'Replace one sugary drink a day with water' },
            { value: 'evening_walk', label: 'Take a 15-minute walk after dinner each evening' },
            { value: 'abs_workout', label: 'Do a 10-minute abs workout three times this week' },
            { value: 'reduce_alcohol', label: 'Reduce alcohol consumption by one drink per week' },
            { value: 'healthy_recipe', label: 'Try a new healthy recipe focused on belly fat-fighting foods' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(nextStep?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'next_step_other',
              attributes: {
                label: "Please specify the other small step you'd like to take:",
                required: true,
              },
            },
          ]
        : []),
      {
        name: 'multiple-choice',
        id: 'potential_obstacles',
        attributes: {
          label: 'What might get in the way of you taking this step?',
          description: 'Choose all that apply',
          multiple: true,
          choices: [
            { value: 'time', label: 'Lack of time' },
            { value: 'energy', label: 'Lack of energy' },
            { value: 'stress', label: 'Stress or emotional factors' },
            { value: 'physical_limitations', label: 'Physical limitations or discomfort' },
            { value: 'resources', label: 'Lack of resources (e.g., equipment, healthy food options)' },
            { value: 'knowledge', label: 'Lack of knowledge or skills' },
            { value: 'support', label: 'Lack of support from others' },
            { value: 'priorities', label: 'Conflicting priorities or commitments' },
            { value: 'motivation', label: 'Motivation or willpower challenges' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(potentialObstacles?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_potential_obstacle',
              attributes: {
                label: 'Please specify the other potential obstacle:',
                required: true,
              },
            },
          ]
        : []),
        {
          name: 'statement',
          id: 'obstacle_intro',
          attributes: {
            label: "This is big!",
            description: "We've got our goal set and we're almost ready to go. {{field:preferred_name}} we have just a few more questions to ensure we achieve that first step as soon as possible."
          },
        },
        {
          name: 'number',
          id: 'age',
          attributes: {
            label: "What's your age?",
            description: "Your age helps us tailor advice to your life stage and health needs, especially for belly fat loss strategies.",
            required: true,
            min: 18,
            max: 120,
          },
        },
      {
        name: 'custom_phone_block',
        id: 'phone-number',
        attributes: {
          label: 'What\'s your preferred cell phone number?',
          description:
            "We will associate your calls and texts with this number",
          required: true,

        },
      },
      {
        name: 'statement',
        id: 'thank_you',
        attributes: {
          label: "Let's save this awesome progress, {{field:preferred_name}}!",
          description: "The next step is to create your account so we can saves these results and get started. I'm looking forward to our first chat where we'll dive deeper into your belly fat loss goals, address potential challenges, and craft a personalized plan just for you. Talk to you soon!",
        },
      },
    ],
    theme: {
      font: 'Urbanist, sans-serif',
      backgroundColor: '#F9F7F3',
      buttonsBgColor: '#005f73',
      answersColor: '#001219',
      questionsColor: '#595959',
      buttonsFontColor: '#ffffff',
      buttonsBorderRadius: 4,
      errorsFontColor: '#ae2012',
      errorsBgColor: '#ffe6e6',
      progressBarFillColor: '#005f73',
      progressBarBgColor: '#e9ecef',
    },
    messages: {
      'label.button.ok': 'OK',
      'label.hintText.enter': 'press <strong>Enter ↵</strong>',
      'label.hintText.multipleSelection': 'Choose as many as you like',
      'block.dropdown.placeholder': 'Type or select an option',
      'block.dropdown.noSuggestions': 'No suggestions available',
      'block.shortText.placeholder': 'Type your answer here',
      'block.longText.placeholder': 'Type your answer here',
      'block.number.placeholder': 'Type your answer here',
      'block.email.placeholder': 'Type your email here',
      'block.defaultThankYouScreen.label': `<h3>You did it!</h3>`,
      'label.errorAlert.required': 'This field is required!',
      'label.errorAlert.email': 'Please enter a valid email address.',
      'label.errorAlert.number': 'Please enter a valid number.',
      'label.errorAlert.url': 'Please enter a valid URL.',
      'label.submitBtn': 'Save & Continue',
    },
    settings: {
      disableProgressBar: false,
      disableWheelSwiping: false,
      disableNavigationArrows: false,
      animationDirection: 'vertical',
      showQuestionsNumbers: false,
      showLettersOnAnswers: true,
    },
    customCSS: `
    
    `,
  };

  if (isCreatingAccount) {
    return (
      <AccountCreationForm
        onAccountCreated={handleAccountCreated}
      />
    );
  }

  return (
    <div className={classes.formContainer}>
      <Form formId={1} formObj={formObj} onSubmit={handleSubmit} applyLogic={false} beforeGoingNext={beforeGoingNext} />
    </div>
  );
}

export default BellyFatSurveyPage;