import { Stack, Text, Button } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { QuestionnaireForm } from './QuestionnaireForm/QuestionnaireForm';
import { Document } from '@medplum/react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Loading } from '../components/Loading';
import { Questionnaire, QuestionnaireResponse, Patient, QuestionnaireItem, QuestionnaireResponseItem, Observation } from '@medplum/fhirtypes';
import { showNotification } from '@mantine/notifications';
import classes from '../common/formsPage.module.css';
import { createReference } from '@medplum/core';
import { VisitInvite } from '../pages/onboarding/VisitInvite'

export function FormsPage(): JSX.Element {
  const medplum = useMedplum();
  const patient = medplum.getProfile() as Patient;
  const navigate = useNavigate();
  const location = useLocation();
  const { formId } = useParams<{ formId: string }>();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [bloodPressureObservation, setBloodPressureObservation] = useState<Observation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [submissionComplete, setSubmissionComplete] = useState(false);

  useEffect(() => {
    const fetchQuestionnaireAndData = async () => {
      if (formId) {
        try {
          const fetchedQuestionnaire = await medplum.readResource('Questionnaire', formId);
          setQuestionnaire(fetchedQuestionnaire);

          // Get the blood pressure observation ID from the URL
          const searchParams = new URLSearchParams(location.search);
          const observationId = searchParams.get('oid');

          if (observationId) {
            const observation = await medplum.readResource('Observation', observationId);
            setBloodPressureObservation(observation);
          }
        } catch (err) {
          setError('Failed to load the form or related data. Please try again later.');
        }
      }
    };

    fetchQuestionnaireAndData();
  }, [medplum, formId, location.search]);

  function findItemByLinkId(items: QuestionnaireResponseItem[], linkId: string): string | boolean | string | undefined {
    for (const item of items) {
      if (item.linkId === linkId && item.answer) {
        // Assumes that each question has only one answer
        const answer = item.answer[0];
        // Return the first value found in the answer object
        return answer.valueBoolean ?? answer.valueString ?? answer.valueDate;
      }
  
      if (item.item) {
        const found = findItemByLinkId(item.item, linkId);
        if (found !== undefined) {
          return found;
        }
      }
    }
    return undefined;
  }

  const handleSubmit = async (formData: Record<string, any>) => {
    try {
      const form = formData as QuestionnaireResponse;
      const formItems = form.item as QuestionnaireItem[];

      // Add the blood pressure observation reference to the QuestionnaireResponse
      if (bloodPressureObservation) {
        form.partOf = [createReference(bloodPressureObservation)];
      }

      const response: QuestionnaireResponse = await medplum.createResource(form);

      // Check for the Questionnaire Identifier
      if (questionnaire?.identifier?.[0]?.value === "888a0d25-348a-42ef-9307-719c758ad00b") {
        const preferredName = findItemByLinkId(formItems, 'preferred-name');
        if (typeof preferredName === 'string' && preferredName?.length >= 2) {
          return <VisitInvite ptName={preferredName} />;
        } else {
          const firstName = findItemByLinkId(formItems, 'legal-first-name');
          if (typeof firstName === 'string' && firstName?.length >= 2) {
            return <VisitInvite ptName={firstName} />;
          }
        }
        return <VisitInvite ptName='' />;
      } else {
        // Generic thank you message and redirect button
        setSubmissionComplete(true);
      }
    } catch (err) {
      setError(`Submission failed: ${err instanceof Error ? err.message : 'Unknown error'}`);
    }
  };

  if (error) {
    return (
      <Document width={800}>
        <Text color="red" size="lg">{error}</Text>
      </Document>
    );
  }

  if (!questionnaire) {
    return <Loading />;
  }

  if (submissionComplete) {
    return (
      <Document width={800}>
        <Stack align="center" gap="md">
          <Text size="xl">Thank you for your submission! I'll follow up over text.</Text>
          <Button onClick={() => navigate('/tasks')}>Return Home</Button>
        </Stack>
      </Document>
    );
  }

  return (
    <Document className={classes.formStyled} width={800}>
      <Stack mt="xl">
        <QuestionnaireForm
          questionnaire={questionnaire}
          onSubmit={handleSubmit}
          subject={createReference(patient)}
        />
      </Stack>
    </Document>
  );
}