import React, { useCallback, useEffect, useState } from 'react';
import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '@quillforms/renderer-core/build-style/style.css';
import { createReference } from '@medplum/core';
import { useMedplum } from '@medplum/react';
import { RegisterForm } from '@/components/CustomRegisterForm';
import { Patient, QuestionnaireResponse } from '@medplum/fhirtypes';
import '../blocks/phoneblock';
import '../blocks/USMailingAddress';
import { parseQuillFormAnswers } from '../blocks/ParseQuillFormAnswers';
import classes from './TestFormPage.module.css';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
import { getConfig } from '../config';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ramonaLogo from '../img/Ramona-Logo-Medium.webp'

import heroImage from '../img/profile/ramona-hero.webp';
import startImage from '../img/profile/entering-health-profile-ramona-ai.webp';
import { Box, Grid, Image } from '@mantine/core';
import { useMatomo } from '@/common/useMatomo';

registerCoreBlocks();

interface SubmissionDispatchers {
  completeForm: () => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSubmissionErr: (error: string) => void;
}

// Define the Choice type
type Choice = {
  value: string;
  label: string;
};

type HealthGoal =
  | 'lose_weight'
  | 'gain_muscle'
  | 'improve_fitness'
  | 'reduce_stress'
  | 'improve_sleep'
  | 'eat_healthier'
  | 'other';

type BeforeGoingNextParams = {
  setIsFieldValid: (id: string, flag: boolean) => void;
  setFieldValidationErr: (id: string, err: string) => void;
  setIsPending: (flag: boolean) => void;
  setIsCurrentBlockSafeToSwipe: (flag: boolean) => void;
  goToBlock: (id: string) => void;
  goNext: () => void;
  currentBlockId: string;
  answers: Record<string, any>;
};

function AccountCreationForm({
  onAccountCreated,
}: {
  onAccountCreated: () => void;

}): JSX.Element {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const medplum = useMedplum();
  const config = getConfig();

  const handleRegister = () => {
        onAccountCreated();  
  };

  return (
    <Grid> 
      <Grid.Col span="auto">
        <Box pt={100} pb={200} px={10}>
          <RegisterForm
            type="patient"
            projectId={config.projectId}
            googleClientId={config.googleClientId}
            recaptchaSiteKey={config.recaptchaSiteKey}
            onSuccess={() => handleRegister()}
          >
            <h3 >Let's Create Your Account</h3>
            
          </RegisterForm>
        </Box>
      </Grid.Col>
    </Grid>
  );
}

export function NewProfilePage(): JSX.Element {
  const medplum = useMedplum();
  const config = getConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const { trackFormEvent } = useMatomo();

  const [surveyData, setSurveyData] = useState<Record<string, any> | null>(null);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const primaryGoal = useFieldAnswer('primary_goal') as HealthGoal | undefined;
  const goalImportance = useFieldAnswer('goal_importance') as string[] | undefined;
  const motivation = useFieldAnswer('motivation') as number | undefined;
  const weightLossMethods = useFieldAnswer('weight_loss_methods') as string[] | undefined;
  const muscleGainDiet = useFieldAnswer('muscle_gain_diet') as string | undefined;
  const preferredActivities = useFieldAnswer('preferred_activities') as string[] | undefined;
  const stressSources = useFieldAnswer('stress_sources') as string[] | undefined;
  const stressReductionTechniques = useFieldAnswer('stress_reduction_techniques') as string[] | undefined;
  const sleepConcern = useFieldAnswer('sleep_concern') as string | undefined;
  const healthyEatingChallenge = useFieldAnswer('healthy_eating_challenge') as string | undefined;
  const dietaryChanges = useFieldAnswer('dietary_changes') as string[] | undefined;
  const nextStep = useFieldAnswer('next_step') as string[] | undefined;
  const potentialObstacles = useFieldAnswer('potential_obstacles') as string[] | undefined;
  const healthConditions = useFieldAnswer('health_conditions') as string[] | undefined;

  useEffect(() => {
    if (queryString && medplum.getProfile()) {
      const patient = medplum.getProfile() as Patient;
      const updatedPatient = {
        ...patient,
        extension: [
          ...(patient.extension || []),
          {
            url: 'http://ramona.care/fhir/StructureDefinition/query-string',
            valueString: queryString,
          },
        ],
      };
      medplum
        .updateResource(updatedPatient)
        .then(() => {
          console.log('Patient resource updated successfully');
        })
        .catch((error) => {
          console.error('Error updating patient resource:', error);
        });
    }
  }, [queryString, medplum]);

  const clickToContinue = (): void => {
    navigate('/');
  };

  const handleSubmit = useCallback(
    (data: Record<string, any>, dispatchers: SubmissionDispatchers) => {
      const { completeForm, setIsSubmitting, setSubmissionErr } = dispatchers;

      console.log('Submitted answers:', data);

      const profile = medplum.getProfile();

      if (profile) {
        // User is authenticated
        const patientReference = createReference(profile as Patient);
        const itemList = parseQuillFormAnswers(data.answers, formObj.blocks);
        const newResponse: QuestionnaireResponse = {
          resourceType: 'QuestionnaireResponse',
          status: 'completed',
          item: itemList,
          questionnaire: `Questionnaire/${config.onboardingId}`,
          subject: patientReference,
          source: patientReference,
          authored: new Date().toISOString(),
          author: patientReference,
        };

        setIsSubmitting(true);
        medplum
          .createResource(newResponse)
          .then((response) => {
            console.log('Created QuestionnaireResponse:', response);
            setIsSubmitting(false);
            completeForm();
            navigate('/select-plan'); // Redirect as needed
          })
          .catch((error) => {
            console.error('Error creating QuestionnaireResponse:', error);
            setSubmissionErr('An error occurred while submitting the form.');
            setIsSubmitting(false);
          });
      } else {
        // User is unauthenticated
        setSurveyData(data);
        setIsCreatingAccount(true);
      }
    },
    [medplum, config.onboardingId, navigate]
  );

  const handleAccountCreated = useCallback(() => {
    const profile = medplum.getProfile() as Patient;
    if (profile && surveyData) {
      const patientReference = createReference(profile);
      const itemList = parseQuillFormAnswers(surveyData.answers, formObj.blocks);
      const newResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        item: itemList,
        questionnaire: `Questionnaire/${config.onboardingId}`,
        subject: patientReference,
        source: patientReference,
        authored: new Date().toISOString(),
        author: patientReference,
      };

      medplum
        .createResource(newResponse)
        .then((response) => {
          console.log('Created QuestionnaireResponse:', response);
          setIsCreatingAccount(false);
          navigate('/select-plan'); // Redirect as needed
        })
        .catch((error) => {
          console.error('Error creating QuestionnaireResponse:', error);
        });
    }
  }, [medplum, surveyData, config.onboardingId, navigate]);

  const beforeGoingNext = ({
    setIsFieldValid,
    setFieldValidationErr,
    setIsPending,
    setIsCurrentBlockSafeToSwipe,
    goToBlock,
    goNext,
    currentBlockId,
    answers,
  }: BeforeGoingNextParams): void => {
    // Track the new block view
    const currentBlock = formObj.blocks.find(block => block.id === currentBlockId);
    if (currentBlock) {
      trackFormEvent(
        'Form Navigation',
        'View Block',
        `${currentBlock.name} - ${currentBlockId}`
      );
    }

    const setFieldValid = (): void => {
      setIsFieldValid(currentBlockId, true);
      setFieldValidationErr(currentBlockId, '');
      setIsCurrentBlockSafeToSwipe(true);
    };

    const setFieldInvalid = (errorMessage: string): void => {
      setIsFieldValid(currentBlockId, false);
      setFieldValidationErr(currentBlockId, errorMessage);
      setIsCurrentBlockSafeToSwipe(false);
    };

    switch (currentBlockId) {
      case 'dob':
        const dob = answers[currentBlockId].value as string;
        const currentDate = new Date();
        const eighteenYearsAgo = new Date(
          currentDate.getFullYear() - 18,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        const dobDate = new Date(dob);

        if (dobDate > eighteenYearsAgo) {
          setFieldInvalid('You must be 18 or older to use this service.');
        } else {
          setFieldValid();
          goNext();
        }
        break;

      default:
        setFieldValid();
        goNext();
    }
  };




  const formObj: FormObj = {
    blocks: [
      {
        name: 'welcome-screen',
        id: 'welcome',
        attributes: {
          label: 'Welcome to Your AI Health Coach Journey!',
          description:
            "Hi there! I'm Ramona, your AI health coach. I'm excited to start this journey with you! This quick survey will help me understand your goals and prepare for our first chat. It should only take about 5 minutes to complete.",
          buttonText: "Let's Get Started",
          attachment: {
            type: 'image',
            url: heroImage,
          },
          layout: 'float-right',
        },
      },
      {
        name: 'short-text',
        id: 'preferred_name',
        attributes: {
          label: "What's your preferred name?",
          required: true,
          description:
            'James, Jim, Jimmy, Jimbo - No matter your preference, I want to be sure I call you what you like.',
        },
      },
      {
        name: 'multiple-choice',
        id: 'primary_goal',
        attributes: {
          label: "What's your primary health goal right now?",
          description:
            "Research shows people are more successful when focusing on one goal at a time. Don't worry – you can work on other areas later in your health journey. Choose the goal that feels most important to you right now.",
          required: true,
          verticalAlign: true,
          choices: [
            { value: 'lose_weight', label: 'Lose weight' },
            { value: 'gain_muscle', label: 'Gain muscle' },
            { value: 'improve_fitness', label: 'Improve overall fitness' },
            { value: 'reduce_stress', label: 'Reduce stress' },
            { value: 'improve_sleep', label: 'Improve sleep' },
            { value: 'eat_healthier', label: 'Eat healthier' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(primaryGoal?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_goal',
              attributes: {
                label: 'Please specify your other health goal:',
                required: true,
              },
            },
          ]
        : []),
{
              name: 'multiple-choice',
  id: 'goal_importance',
              attributes: {
    label: 'Why is this goal important to you?',
                description: 'Choose all that apply',
                multiple: true,
    choices: 
      primaryGoal?.includes('lose_weight')
        ? [
            { value: 'improve_health', label: 'Improve my overall health' },
            { value: 'confidence_appearance', label: 'Feel more confident about my appearance' },
            { value: 'energy_throughout_day', label: 'Have more energy throughout the day' },
            { value: 'reduce_health_risks', label: 'Reduce health risks (e.g., diabetes, heart disease)' },
            { value: 'fit_favorite_clothes', label: 'Fit into my favorite clothes' },
            { value: 'keep_up_with_family', label: 'Keep up with my kids/grandkids' },
                  { value: 'other', label: 'Other' },
          ]
        : primaryGoal?.includes('gain_muscle')
        ? [
            { value: 'increase_strength', label: 'Increase my strength for daily activities' },
            { value: 'improve_performance', label: 'Improve my athletic performance' },
            { value: 'enhance_appearance', label: 'Enhance my physical appearance' },
            { value: 'boost_metabolism', label: 'Boost my metabolism' },
            { value: 'support_bone_health', label: 'Support bone health as I age' },
            { value: 'feel_confident', label: 'Feel more confident' },
                  { value: 'other', label: 'Other' },
          ]
        : primaryGoal?.includes('improve_fitness')
        ? [
            { value: 'more_energy', label: 'Have more energy for daily activities' },
            { value: 'reduce_disease_risk', label: 'Reduce the risk of chronic diseases' },
            { value: 'improve_mood', label: 'Improve my mood and mental health' },
            { value: 'enhance_quality_of_life', label: 'Enhance my quality of life as I age' },
            { value: 'keep_up_with_others', label: 'Keep up with friends/family in activities' },
            { value: 'challenge_myself', label: 'Challenge myself and set new personal records' },
                  { value: 'other', label: 'Other' },
          ]
        : primaryGoal?.includes('reduce_stress')
        ? [
            { value: 'improve_mental_health', label: 'Improve my mental health and well-being' },
            { value: 'enhance_work_performance', label: 'Enhance my work performance' },
            { value: 'better_relationships', label: 'Have better relationships with family/friends' },
            { value: 'sleep_better', label: 'Sleep better at night' },
            { value: 'reduce_stress_symptoms', label: 'Reduce physical symptoms of stress (e.g., headaches, tension)' },
            { value: 'enjoy_life_more', label: 'Enjoy life more fully' },
                  { value: 'other', label: 'Other' },
          ]
        : primaryGoal?.includes('improve_sleep')
        ? [
            { value: 'more_energy', label: 'Have more energy during the day' },
            { value: 'improve_mood_clarity', label: 'Improve my mood and mental clarity' },
            { value: 'support_physical_health', label: 'Support my physical health' },
            { value: 'enhance_performance', label: 'Enhance my work/academic performance' },
            { value: 'reduce_reliance', label: 'Reduce reliance on caffeine or sleep aids' },
            { value: 'wake_refreshed', label: 'Wake up feeling refreshed and ready for the day' },
                  { value: 'other', label: 'Other' },
          ]
        : primaryGoal?.includes('eat_healthier')
        ? [
            { value: 'improve_overall_health', label: 'Improve my overall health' },
            { value: 'increase_energy', label: 'Increase my energy levels' },
            { value: 'manage_weight', label: 'Manage my weight' },
            { value: 'reduce_disease_risk', label: 'Reduce risk of chronic diseases' },
            { value: 'better_digestion', label: 'Improve digestion and gut health' },
            { value: 'set_good_example', label: 'Set a good example for family/friends' },
                  { value: 'other', label: 'Other' },
          ]
        : [
            { value: 'improve_quality_of_life', label: 'To improve my overall quality of life' },
            { value: 'manage_health_condition', label: 'To manage a specific health condition' },
            { value: 'achieve_milestone', label: 'To achieve a personal milestone or challenge' },
            { value: 'support_mental_health', label: 'To support my mental health and well-being' },
            { value: 'enhance_performance', label: 'To enhance my daily functioning or performance' },
            { value: 'align_with_values', label: 'To align with my personal values or beliefs' },
            { value: 'other', label: 'Other' },
          ]
  },
},
      ...(goalImportance?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_importance',
              attributes: {
                label: 'Please specify the other reason this goal is important to you:',
              },
            },
          ]
        : []),
      {
        name: 'slider',
        id: 'motivation',
        attributes: {
          label: 'On a scale of 1-10, how motivated are you to achieve your health goal?',
          description: '1: Not at all, 10: Extremely',
          min: 1,
          max: 10,
          step: 1,
        },
      },
      {
        name: 'statement',
        id: 'goal_specific_questions',
        attributes: {
          label:
            motivation === undefined || motivation < 5
              ? "We're Just Getting Started, {{field:preferred_name}}!"
              : 'Great Start, {{field:preferred_name}}!',
          description:
            motivation === undefined || motivation < 5
              ? "While you may not have the motivation now, making progress is possible. Let's continue to define a first step that you can achieve in days."
              : "I'm excited to help figure out your first goal. Let's continue to define a first step that you can achieve in days.",
          attachment: {
            type: 'image',
            url: startImage,
          },
          layout: 'split-right',
        },
      },
      ...(primaryGoal?.includes('lose_weight')
        ? [
            {
              name: 'number',
              id: 'weight_loss_goal',
              attributes: {
                label: 'How much weight would you like to lose? (in pounds)',
                min: 5,
                setMin: true,
                max: 500,
                setMax: true,
              },
            },
            {
              name: 'multiple-choice',
              id: 'weight_loss_methods',
              attributes: {
                label: 'Have you tried any specific diets or weight loss methods in the past?',
                multiple: true,
                choices: [
                  { value: 'calorie_counting', label: 'Calorie counting' },
                  { value: 'low_carb', label: 'Low-carb diet' },
                  { value: 'intermittent_fasting', label: 'Intermittent fasting' },
                  { value: 'weight_loss_programs', label: 'Weight loss programs (e.g., Weight Watchers)' },
                  { value: 'other', label: 'Other' },
                  { value: 'none', label: "No, I haven't tried any specific methods" },
                ] as Choice[],
              },
            },
            ...(weightLossMethods?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'other_weight_loss_method',
                    attributes: {
                      label: "Please specify the other weight loss method you've tried:",
                    },
                  },
                ]
              : []),
            {
              name: 'multiple-choice',
              id: 'weight_loss_challenge',
              attributes: {
                label: "What's your biggest challenge when it comes to losing weight?",
                choices: [
                  { value: 'portions', label: 'Controlling portions' },
                  { value: 'cravings', label: 'Resisting cravings' },
                  { value: 'exercise_time', label: 'Finding time to exercise' },
                  { value: 'emotional_eating', label: 'Emotional eating' },
                  { value: 'nutrition_knowledge', label: 'Lack of knowledge about nutrition' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
          ]
        : []),
      ...(primaryGoal?.includes('gain_muscle')
        ? [
            {
              name: 'multiple-choice',
              id: 'strength_training_experience',
              attributes: {
                label: 'Do you have experience with strength training?',
                choices: [
                  { value: 'significant', label: 'Significant experience' },
                  { value: 'some', label: 'Some experience' },
                  { value: 'none', label: 'No experience' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'workout_equipment',
              attributes: {
                label: 'Do you have access to any of the following?',
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'gym', label: 'Gym membership' },
                  { value: 'home_equipment', label: 'Home workout equipment' },
                  { value: 'resistance_bands', label: 'Resistance bands' },
                  { value: 'none', label: 'None of the above' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'muscle_gain_diet',
              attributes: {
                label: 'Are you currently following any specific diet to support muscle gain?',
                choices: [
                  { value: 'high_protein', label: 'High-protein diet' },
                  { value: 'bulking', label: 'Bulking diet' },
                  { value: 'no_specific', label: 'No specific diet' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(muscleGainDiet === 'other'
              ? [
                  {
                    name: 'short-text',
                    id: 'other_muscle_gain_diet',
                    attributes: {
                      label: 'Please specify your muscle gain diet:',
                    },
                  },
                ]
              : []),
          ]
        : []),
      ...(primaryGoal?.includes('improve_fitness')
        ? [
            {
              name: 'multiple-choice',
              id: 'fitness_aspects',
              attributes: {
                label: 'What aspects of fitness are you most interested in improving?',
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'cardio', label: 'Cardiovascular endurance' },
                  { value: 'strength', label: 'Strength' },
                  { value: 'flexibility', label: 'Flexibility' },
                  { value: 'balance', label: 'Balance' },
                  { value: 'overall', label: 'Overall physical health' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'exercise_commitment',
              attributes: {
                label: 'How many days per week can you commit to exercise?',
                choices: [
                  { value: '1-2', label: '1-2 days' },
                  { value: '3-4', label: '3-4 days' },
                  { value: '5+', label: '5+ days' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'preferred_activities',
              attributes: {
                label: 'What types of physical activities do you enjoy or are interested in trying?',
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'walking_jogging', label: 'Walking/Jogging' },
                  { value: 'cycling', label: 'Cycling' },
                  { value: 'swimming', label: 'Swimming' },
                  { value: 'weight_training', label: 'Weight training' },
                  { value: 'yoga_pilates', label: 'Yoga/Pilates' },
                  { value: 'group_fitness', label: 'Group fitness classes' },
                  { value: 'team_sports', label: 'Team sports' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(preferredActivities?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'other_preferred_activity',
                    attributes: {
                      label: 'Please specify your other preferred physical activity:',
                    },
                  },
                ]
              : []),
          ]
        : []),
      ...(primaryGoal?.includes('reduce_stress')
        ? [
            {
              name: 'slider',
              id: 'stress_level',
              attributes: {
                label: 'On a scale of 1-10, how would you rate your stress level over the past week?',
                description:
                  '1: Very low, 10: Very high. Consider your general stress level over the past week, not just at this moment. This helps us understand your typical stress patterns and provide more relevant support.',
                min: 1,
                max: 10,
                step: 1,
              },
            },
            {
              name: 'multiple-choice',
              id: 'stress_sources',
              attributes: {
                label: 'What are your primary sources of stress?',
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'work', label: 'Work/Career' },
                  { value: 'relationships', label: 'Relationships' },
                  { value: 'financial', label: 'Financial concerns' },
                  { value: 'health', label: 'Health issues' },
                  { value: 'family', label: 'Family responsibilities' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(stressSources?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'other_stress_source',
                    attributes: {
                      label: 'Please specify your other primary source of stress:',
                    },
                  },
                ]
              : []),
            {
              name: 'multiple-choice',
              id: 'stress_reduction_techniques',
              attributes: {
                label: 'Have you tried any stress-reduction techniques before?',
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'meditation', label: 'Meditation' },
                  { value: 'deep_breathing', label: 'Deep breathing exercises' },
                  { value: 'yoga', label: 'Yoga' },
                  { value: 'exercise', label: 'Regular exercise' },
                  { value: 'therapy', label: 'Therapy or counseling' },
                  { value: 'other', label: 'Other' },
                  { value: 'none', label: "I haven't tried any specific techniques" },
                ] as Choice[],
              },
            },
            ...(stressReductionTechniques?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'other_stress_reduction_technique',
                    attributes: {
                      label: "Please specify the other stress-reduction technique you've tried:",
                    },
                  },
                ]
              : []),
          ]
        : []),
      ...(primaryGoal?.includes('improve_sleep')
        ? [
            {
              name: 'multiple-choice',
              id: 'sleep_duration',
              attributes: {
                label: 'On average, how many hours of sleep do you get per night?',
                choices: [
                  { value: 'less_than_5', label: 'Less than 5 hours' },
                  { value: '5-6', label: '5-6 hours' },
                  { value: '6-7', label: '6-7 hours' },
                  { value: '7-8', label: '7-8 hours' },
                  { value: 'more_than_8', label: 'More than 8 hours' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'sleep_concern',
              attributes: {
                label: "What's your main sleep concern?",
                choices: [
                  { value: 'falling_asleep', label: 'Difficulty falling asleep' },
                  { value: 'staying_asleep', label: 'Waking up during the night' },
                  { value: 'waking_early', label: 'Waking up too early' },
                  { value: 'not_rested', label: 'Not feeling rested after sleep' },
                  { value: 'irregular_schedule', label: 'Irregular sleep schedule' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(sleepConcern === 'other'
              ? [
                  {
                    name: 'short-text',
                    id: 'other_sleep_concern',
                    attributes: {
                      label: 'Please specify your main sleep concern:',
                    },
                  },
                ]
              : []),
            {
              name: 'multiple-choice',
              id: 'bedtime_routine',
              attributes: {
                label: 'Do you currently have a bedtime routine?',
                multiple: false,
                choices: [
                  { value: 'consistent', label: 'Yes, I have a consistent routine' },
                  { value: 'inconsistent', label: "I have a routine but don't always follow it" },
                  { value: 'no_routine', label: "No, I don't have a specific routine" },
                ] as Choice[],
              },
            },
          ]
        : []),
      ...(primaryGoal?.includes('eat_healthier')
        ? [
            {
              name: 'multiple-choice',
              id: 'meals_at_home',
              attributes: {
                label: 'How many meals do you typically prepare at home per week?',
                choices: [
                  { value: '0-3', label: '0-3 meals' },
                  { value: '4-7', label: '4-7 meals' },
                  { value: '8-14', label: '8-14 meals' },
                  { value: '15+', label: '15+ meals' },
                ] as Choice[],
              },
            },
            {
              name: 'multiple-choice',
              id: 'healthy_eating_challenge',
              attributes: {
                label: "What's your biggest challenge when it comes to eating healthy?",
                choices: [
                  { value: 'time', label: 'Lack of time for meal preparation' },
                  { value: 'cooking_skills', label: 'Limited cooking skills' },
                  { value: 'cravings', label: 'Unhealthy food cravings' },
                  { value: 'cost', label: 'Cost of healthy foods' },
                  { value: 'knowledge', label: 'Lack of knowledge about nutrition' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(healthyEatingChallenge === 'other'
              ? [
                  {
                    name: 'short-text',
                    id: 'other_healthy_eating_challenge',
                    attributes: {
                      label: 'Please specify your biggest challenge when it comes to eating healthy:',
                    },
                  },
                ]
              : []),
            {
              name: 'multiple-choice',
              id: 'dietary_changes',
              attributes: {
                label: "Are there any specific dietary changes you're interested in making?",
                description: 'Select all that apply',
                multiple: true,
                choices: [
                  { value: 'more_fruits_veggies', label: 'Eating more fruits and vegetables' },
                  { value: 'less_processed_food', label: 'Reducing processed food intake' },
                  { value: 'portion_control', label: 'Controlling portion sizes' },
                  { value: 'less_sugar', label: 'Reducing sugar intake' },
                  { value: 'more_protein', label: 'Increasing protein intake' },
                  { value: 'other', label: 'Other' },
                ] as Choice[],
              },
            },
            ...(dietaryChanges?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'other_dietary_change',
                    attributes: {
                      label: "Please specify the other dietary change you're interested in making:",
                    },
                  },
                ]
              : []),
          ]
        : []),
        {
          name: 'multiple-choice',
          id: 'next_step',
          attributes: {
            label: "What's one small step you feel ready to take towards your health goal this week?",
            description:
              'Small steps lead to big changes! Choosing an achievable action helps build momentum and confidence. Remember, consistency matters more than perfection.',
            choices: 
              primaryGoal?.includes('lose_weight') 
                ? [
                    { value: 'track_food', label: 'Track my food intake for three days this week' },
                    { value: 'water_replace', label: 'Replace one sugary drink a day with water' },
                    { value: 'evening_walk', label: 'Take a 15-minute walk after dinner each evening' },
                    { value: 'healthy_meal', label: 'Prepare one healthy meal at home instead of eating out' },
                    { value: 'portion_control', label: 'Reduce portion sizes at two meals each day' },
                    { value: 'new_recipe', label: 'Try a new healthy recipe this week' },
                    { value: 'other', label: 'Other' },
                  ]
                : primaryGoal?.includes('gain_muscle')
                ? [
                    { value: 'bodyweight_workout', label: 'Do a 15-minute bodyweight workout three times this week' },
                    { value: 'protein_rich_food', label: 'Add a protein-rich food to two meals each day' },
                    { value: 'learn_exercise', label: 'Learn and practice proper form for one new strength exercise' },
                    { value: 'increase_water', label: 'Increase my water intake by two glasses a day' },
                    { value: 'extra_sleep', label: 'Get an extra 30 minutes of sleep each night' },
                    { value: 'meal_plan', label: 'Research and plan a balanced muscle-building meal plan' },
                    { value: 'other', label: 'Other' },
                  ]
                : primaryGoal?.includes('improve_fitness')
                ? [
                    { value: 'daily_walk', label: 'Take a 10-minute brisk walk every day this week' },
                    { value: 'new_exercise', label: 'Try a new type of exercise or fitness class' },
                    { value: 'morning_stretch', label: 'Do a 5-minute stretching routine each morning' },
                    { value: 'take_stairs', label: 'Take the stairs instead of the elevator whenever possible' },
                    { value: 'hourly_movement', label: 'Stand up and move for 5 minutes every hour during the workday' },
                    { value: 'schedule_workouts', label: 'Schedule two 30-minute workout sessions this week' },
                    { value: 'other', label: 'Other' },
                  ]
                : primaryGoal?.includes('reduce_stress')
                ? [
                    { value: 'deep_breathing', label: 'Practice 5 minutes of deep breathing or meditation daily' },
                    { value: 'nature_walk', label: 'Take a 15-minute nature walk three times this week' },
                    { value: 'bedtime_routine', label: 'Establish a relaxing bedtime routine' },
                    { value: 'gratitude_journal', label: 'Write in a gratitude journal before bed each night' },
                    { value: 'reduce_screen_time', label: 'Reduce screen time by 30 minutes each day' },
                    { value: 'stress_reducing_activity', label: 'Try a stress-reducing activity (e.g., yoga, painting, gardening)' },
                    { value: 'other', label: 'Other' },
                  ]
                : primaryGoal?.includes('improve_sleep')
                ? [
                    { value: 'consistent_bedtime', label: 'Set a consistent bedtime and stick to it for five nights' },
                    { value: 'sleep_environment', label: 'Create a calm, dark sleep environment' },
                    { value: 'avoid_screens', label: 'Avoid screens for one hour before bedtime' },
                    { value: 'relaxation_technique', label: 'Try a 10-minute relaxation technique before bed each night' },
                    { value: 'limit_caffeine', label: 'Limit caffeine intake after 2 PM' },
                    { value: 'consistent_wakeup', label: 'Wake up at the same time each morning, including weekends' },
                    { value: 'other', label: 'Other' },
                  ]
                : primaryGoal?.includes('eat_healthier')
                ? [
                    { value: 'extra_vegetables', label: 'Add an extra serving of vegetables to two meals each day' },
                    { value: 'pack_lunch', label: 'Prepare and pack a healthy lunch for work/school three days this week' },
                    { value: 'whole_food_snack', label: 'Replace one processed snack with a whole food alternative daily' },
                    { value: 'water_instead', label: 'Drink water instead of sugary beverages for all meals' },
                    { value: 'new_recipe', label: 'Try one new healthy recipe this week' },
                    { value: 'read_labels', label: 'Read nutrition labels when grocery shopping this week' },
                    { value: 'other', label: 'Other' },
                  ]
                : [
                    { value: 'research_goal', label: 'Research more about my specific health goal' },
                    { value: 'daily_time', label: 'Set aside 15 minutes each day to work towards my goal' },
                    { value: 'share_goal', label: 'Share my health goal with a supportive friend or family member' },
                    { value: 'tracking_system', label: 'Create a simple tracking system for my progress' },
                    { value: 'identify_obstacle', label: 'Identify and plan to overcome one obstacle to my goal' },
                    { value: 'schedule_checkup', label: 'Schedule a check-up or consultation related to my health goal' },
                    { value: 'other', label: 'Other' },
                  ]
          },
        },
      ...(nextStep?.includes('other')
              ? [
                  {
                    name: 'short-text',
                    id: 'next_step_other',
                    attributes: {
                      label: "Please specify the small step you'd like to take instead:",
                      description: ""
                    },
                  },
                ]
              : []),
      {
        name: 'multiple-choice',
        id: 'potential_obstacles',
        attributes: {
          label: 'What might get in the way of you taking this step?',
          description: 'Choose all that apply',
          multiple: true,
          choices: [
            { value: 'time', label: 'Lack of time' },
            { value: 'energy', label: 'Lack of energy' },
            { value: 'stress', label: 'Stress or emotional factors' },
            { value: 'physical_limitations', label: 'Physical limitations or discomfort' },
            { value: 'resources', label: 'Lack of resources (e.g., equipment, healthy food options)' },
            { value: 'knowledge', label: 'Lack of knowledge or skills' },
            { value: 'support', label: 'Lack of support from others' },
            { value: 'priorities', label: 'Conflicting priorities or commitments' },
            { value: 'motivation', label: 'Motivation or willpower challenges' },
            { value: 'other', label: 'Other' },
          ] as Choice[],
        },
      },
      ...(potentialObstacles?.includes('other')

              ? [
                  {
                    name: 'short-text',
                    id: 'potential_obstacles_other',
                    attributes: {
                      label: "Please specify the other items that may get in the way of taking this step:",
                    },
                  },
                ]
              : []),
              {
                name: 'statement',
                id: 'goal_read',
                attributes: {
                  label: 'We\'re on our way, {{field:preferred_name}}!',
                  description: "Now that we have a goal and a first step, let's cover a few more questions to ensure I can provide thoughtful support.",
                },
              },
      {
        name: 'multiple-choice',
        id: 'communication_style',
        attributes: {
          label: 'How would you like me to communicate with you?',
          required: true,
          choices: [
            { value: 'motivational', label: 'Motivational and encouraging' },
            { value: 'factual', label: 'Factual and informative' },
            { value: 'nurturing', label: 'Nurturing and supportive' },
            { value: 'direct', label: 'Direct and to-the-point' },
            { value: 'mix', label: 'Mix of styles' },
          ] as Choice[],
        },
      },
      {
        name: 'multiple-choice',
        id: 'activity_level',
        attributes: {
          label: 'How would you describe your current activity level?',
          description:
            'Your activity level influences various aspects of health, including sleep, stress, nutrition, and fitness. Understanding your current activity level helps us tailor advice for your overall well-being.',
          required: true,
          choices: [
            { value: 'sedentary', label: 'Sedentary (little to no exercise)' },
            { value: 'lightly_active', label: 'Lightly active (light exercise 1-3 days/week)' },
            { value: 'moderately_active', label: 'Moderately active (moderate exercise 3-5 days/week)' },
            { value: 'very_active', label: 'Very active (hard exercise 6-7 days/week)' },
          ] as Choice[],
        },
      },
      {
        name: 'date',
        id: 'dob',
        attributes: {
          label: "What's your birthdate?",
          description: 'Your age helps us tailor advice to your life stage and health needs.',
          required: true,
        },
      },
      {
        name: 'group',
        id: 'height',
        attributes: {
          label: "What's your height? (in feet/inches or centimeters)",
          description: 'Height is used to calculate health metrics and personalize fitness recommendations.',
        },
        innerBlocks: [
          {
            name: 'number',
            id: 'height-feet',
            attributes: {
              required: true,
              label: 'Feet',
              description: '',
              setMin: true,
              min: 3,
              setMax: true,
              max: 8,
            },
          },
          {
            name: 'number',
            id: 'height-inches',
            attributes: {
              required: true,
              label: 'Inches',
              description: 'Height is used to calculate health metrics and personalize fitness recommendations.',
              setMin: true,
              min: 0,
              setMax: true,
              max: 11,
            },
          },
        ],
      },
      {
        name: 'number',
        id: 'weight',
        attributes: {
          label: "What's your current weight? (in pounds)",
          description: 'Weight helps us track progress and customize nutrition and exercise plans to your needs.',
          required: false,
          setMin: true,
          min: 50,
          setMax: true,
          max: 1000,
        },
      },
      {
        name: 'multiple-choice',
        id: 'health_conditions',
        attributes: {
          label: 'Do you have any health conditions I should be aware of?',
          description:
            "Knowing about any health conditions ensures our recommendations are safe and appropriate for your specific situation. Remember, we're not offering medical advice or replacing your healthcare provider – always consult with them about your health concerns.",
          multiple: true,
          required: true,
          choices: [
            { value: 'heart_condition', label: 'Heart condition' },
            { value: 'high_blood_pressure', label: 'High blood pressure' },
            { value: 'respiratory_condition', label: 'Respiratory condition (e.g., asthma, COPD)' },
            { value: 'diabetes', label: 'Diabetes' },
            { value: 'pregnancy', label: 'Pregnancy' },
            { value: 'allergies', label: 'Severe allergies or food intolerances' },
            { value: 'recent_surgery', label: 'Recent surgery or injury' },
            { value: 'mobility_condition', label: 'Condition affecting mobility' },
            { value: 'mental_health', label: 'Mental health condition' },
            { value: 'other', label: 'Other condition requiring medical supervision' },
            { value: 'none', label: 'None of the above' },
          ] as Choice[],
        },
      },
      ...(healthConditions?.includes('other')
        ? [
            {
              name: 'short-text',
              id: 'other_health_condition',
              attributes: {
                label: 'Please specify your other health condition:',
              },
            },
          ]
        : []),
        {
          name: 'custom_phone_block',
          id: 'phone-number',
          attributes: {
            label: 'What\'s your preferred cell phone number?',
            description:
              "We will associate calls and texts with you from this number",
            required: true,
  
          },
        },
        {
        name: 'statement',
        id: 'thank_you',
        attributes: {
          label: "Let's save this awesome progress, {{field:preferred_name}}!",
          description: "The next step is to create your account so we can save these results and get started. I'm looking forward to our first chat where we'll dive deeper into your goals, address potential challenges to your next step, and craft a personalized plan just for you. Talk to you soon!",
        },
      },

    ],
    theme: {
      font: 'Urbanist, sans-serif',
      backgroundColor: '#F9F7F3',
      buttonsBgColor: '#005f73',
      answersColor: '#001219',
      questionsColor: '#595959',
      buttonsFontColor: '#ffffff',
      buttonsBorderRadius: 4,
      errorsFontColor: '#ae2012',
      errorsBgColor: '#ffe6e6',
      progressBarFillColor: '#005f73',
      progressBarBgColor: '#e9ecef',
    },
    messages: {
      'label.button.ok': 'OK',
      'label.hintText.enter': 'press <strong>Enter ↵</strong>',
      'label.hintText.multipleSelection': 'Choose as many as you like',
      'block.dropdown.placeholder': 'Type or select an option',
      'block.dropdown.noSuggestions': 'No suggestions available',
      'block.shortText.placeholder': 'Type your answer here',
      'block.longText.placeholder': 'Type your answer here',
      'block.number.placeholder': 'Type your answer here',
      'block.email.placeholder': 'Type your email here',
      'block.defaultThankYouScreen.label': `<h3>You did it!</h3>`,
      'label.errorAlert.required': 'This field is required!',
      'label.errorAlert.email': 'Please enter a valid email address.',
      'label.errorAlert.number': 'Please enter a valid number.',
      'label.errorAlert.url': 'Please enter a valid URL.',
      'label.submitBtn': 'Save & Continue',
    },
    settings: {
      disableProgressBar: false,
      disableWheelSwiping: false,
      disableNavigationArrows: false,
      animationDirection: 'vertical',
      showQuestionsNumbers: false,
      showLettersOnAnswers: true,
    },
    customCSS: `

    `,
  };

  if (isCreatingAccount) {
    return (
      <AccountCreationForm
        onAccountCreated={handleAccountCreated}
      />
    );
  }

  return (
    <div className={classes.formContainer}>
      <Form formId={1} formObj={formObj} onSubmit={handleSubmit} applyLogic={false} beforeGoingNext={beforeGoingNext} />
    </div>
  );
}
