import { useEffect, useState } from 'react';
import { 
  Button,
  Group,
  Select,
  Stack,
  Text,
  Textarea,
  Alert
} from '@mantine/core';
import { IconThumbUp, IconThumbDown } from '@tabler/icons-react';
import { useMedplum } from '@medplum/react';
import { QuestionnaireResponse, Reference } from '@medplum/fhirtypes';
import { Interaction, getInteractionDescription, getInteractionIcon } from './types';
import { getConfig } from '../../config';


interface FeedbackFormProps {
  onSubmit?: () => void;
  selectedInteraction?: Interaction;
}

interface FeedbackState {
  rating: string;
  category: string;
  details: string;
}

export function FeedbackForm({ onSubmit, selectedInteraction }: FeedbackFormProps): JSX.Element {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const patientId = profile?.id;
  const config = getConfig();
  
  if (!patientId) {
    throw new Error('Patient ID not found');
  }

  const [step, setStep] = useState(0);
  const [feedback, setFeedback] = useState<FeedbackState>({
    rating: '',
    category: '',
    details: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [autoCloseTimeout, setAutoCloseTimeout] = useState<NodeJS.Timeout | null>(null);

  const categories = [
    { value: 'voice', label: 'Voice/Audio Quality' },
    { value: 'understanding', label: 'Understanding My Needs' },
    { value: 'usefulness', label: 'Usefulness of Advice' },
    { value: 'technical', label: 'Technical Issues' },
    { value: 'other', label: 'Other' }
  ];

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      let basedOn;

      if (selectedInteraction) {
        basedOn = {
          reference: `${selectedInteraction.resourceType}/${selectedInteraction.id}`
        };
      }
  
      const questionnaireResponse: QuestionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        status: 'completed',
        questionnaire: `Questionnaire/${config.itemFeedbackSurvey}`,
        subject: basedOn,
        source: { reference: `Patient/${patientId}` },
        authored: new Date().toISOString(),
        item: [
          {
            linkId: 'rating',
            text: 'How was your experience with this interaction?',
            answer: [{
              valueCoding: {
                system: 'http://ramona.care/CodeSystem/interaction-rating',
                code: feedback.rating,
                display: feedback.rating === 'positive' ? 'Helpful' : 'Not Helpful'
              }
            }]
          },
          {
            linkId: 'category',
            text: 'What aspect would you like to comment on?',
            answer: [{
              valueCoding: {
                system: 'http://ramona.care/CodeSystem/feedback-category',
                code: feedback.category,
                display: categories.find(c => c.value === feedback.category)?.label
              }
            }]
          }
        ]
      };
  
      // Add details if provided
      if (feedback.details) {
        questionnaireResponse.item?.push({
          linkId: 'details',
          text: 'Additional details',
          answer: [{
            valueString: feedback.details
          }]
        });
      }
  
      await medplum.createResource(questionnaireResponse);
      setSubmitted(true);

      // Set a 3 second timeout before calling onSubmit
      const timeout = setTimeout(() => {
        if (onSubmit) {
          onSubmit();
        }
      }, 3000);
      
      setAutoCloseTimeout(timeout);
    } catch (error) {
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (autoCloseTimeout) {
        clearTimeout(autoCloseTimeout);
      }
    };
  }, [autoCloseTimeout]);

  if (submitted) {
    return (
      <Alert color="green" title="Thank you!">
        Your feedback has been submitted successfully.
        <Text size="sm" mt="xs" c="dimmed">This message will close in 3 seconds...</Text>
      </Alert>
    );
  }

  return (
    <Stack gap="md">
      {step === 0 && (
        <Stack>
          {selectedInteraction && (
            <Text size="sm" td="underline" ta="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {getInteractionIcon(selectedInteraction.type)}
              </span>
              {getInteractionDescription(selectedInteraction)}
            </Text>
          )}
          <Text fw={500} ta="center">How was your experience with this interaction?</Text>
          <Group justify="center" gap="xl">
            <Button
              variant="subtle" 
              leftSection={<IconThumbUp />}
              onClick={() => {
                setFeedback(prev => ({ ...prev, rating: 'positive' }));
                setStep(1);
              }}
            >
              Helpful
            </Button>
            <Button
              variant="subtle"
              leftSection={<IconThumbDown />}
              onClick={() => {
                setFeedback(prev => ({ ...prev, rating: 'negative' }));
                setStep(1);
              }}
            >
              Not Helpful
            </Button>
          </Group>
        </Stack>
      )}

      {step === 1 && (
        <Stack>
          <Text fw={500}>What aspect would you like to comment on?</Text>
          <Select
            data={categories}
            placeholder="Select a category"
            value={feedback.category}
            onChange={(value) => {
              setFeedback(prev => ({ ...prev, category: value || '' }));
              setStep(2);
            }}
          />
        </Stack>
      )}

      {step === 2 && (
        <Stack>
          <Text fw={500}>Additional details (optional)</Text>
          <Textarea
            value={feedback.details}
            onChange={(e) => setFeedback(prev => ({ ...prev, details: e.target.value }))}
            placeholder="Tell us more about your experience..."
            minRows={3}
          />
          <Group justify="flex-end">
            <Button 
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!feedback.category}
            >
              Submit Feedback
            </Button>
          </Group>
        </Stack>
      )}
    </Stack>
  );
}