import { IconThumbUp, IconThumbDown, IconMessage, IconPhone, IconClipboardList } from '@tabler/icons-react';

export type InteractionType = 'message' | 'call' | 'task';

export interface Interaction {
  id: string;
  type: InteractionType;
  description: string;
  timestamp: string;
  resourceType: 'Communication' | 'Task' | 'Encounter';
  title?: string; // Add title field for display purposes
}

export const getInteractionDescription = (interaction: Interaction) => {
    const description = interaction.description.length > 50 
      ? interaction.description.substring(0, 50) + '...'
      : interaction.description;
      
    switch (interaction.type) {
      case 'call':
        return `Phone Call: ${description}`;
      case 'message':
        return `Message: ${description}`;
      case 'task':
        return `Task: ${description}`;
    }
  };

export const getInteractionIcon = (type: InteractionType) => {
    switch (type) {
      case 'call':
        return <IconPhone size={16} />;
      case 'message':
        return <IconMessage size={16} />;
      case 'task':
        return <IconClipboardList size={16} />;
    }
  };