import { Anchor, Box, Container, NativeSelect, Space, Stack, Text, TextInput, Title } from '@mantine/core';
import { formatAddress, formatFamilyName, formatGivenName, formatHumanName } from '@medplum/core';
import { HumanName, Patient } from '@medplum/fhirtypes';
import { Form, ResourceAvatar, useMedplumProfile } from '@medplum/react';
import { InfoSection } from '../../components/InfoSection';
import { formatPhoneNumber, PhoneNumber } from 'react-phone-number-input';

export function Profile(): JSX.Element | null {
  const profile = useMedplumProfile() as Patient;
  const phoneNumber = profile.telecom?.find((t) => t.system === 'phone')?.value;

  return (
    <Container size="sm" px="xs">
      <Form
        onSubmit={(formData: Record<string, string>) => {
          console.log('formData', formData);
        }}
      >
        <Stack align="center">
          <ResourceAvatar 
            size={200} 
            radius={100} 
            value={profile}
            style={{
              '@media (max-width: 768px)': {
                width: '150px',
                height: '150px'
              }
            }} 
          />
          <Title order={2} ta="center" style={{ wordBreak: 'break-word' }}>
            {formatHumanName(profile.name?.[0] as HumanName)}
          </Title>
          
          <InfoSection title="Personal Information">
            <Space h="sm"/>
            <Text ta="center">
              Email us at <Anchor href="mailto:support@ramona.care?subject=Profile Update Request" target="_blank">
                support@ramona.care
              </Anchor> to update any of this information
            </Text>
            <Box p={{ base: 'xs', sm: 'xl' }} w="100%">
              <Stack>
                <TextInput
                  label="First Name"
                  name="givenName"
                  disabled={true}
                  defaultValue={formatGivenName(profile.name?.[0] as HumanName)}
                />
                <TextInput
                  label="Last Name"
                  name="familyName"
                  disabled={true}
                  defaultValue={formatFamilyName(profile.name?.[0] as HumanName)}
                />
                <TextInput
                  label="Phone Number"
                  name="phoneNumber"
                  disabled={true}
                  defaultValue={phoneNumber ? formatPhoneNumber(phoneNumber) : ''}
                />
                <TextInput
                  label="Email"
                  name="email"
                  disabled={true}
                  defaultValue={profile.telecom?.find((t) => t.system === 'email')?.value}
                />
              </Stack>
            </Box>
          </InfoSection>
        </Stack>
      </Form>
    </Container>
  );
}