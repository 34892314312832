import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMedplum, Document } from '@medplum/react';
import { Task } from '@medplum/fhirtypes';
import { Loading } from '../components/Loading';
import { Title, Card, Text, Stack, Group, Badge, Divider, Box } from '@mantine/core';
import { FeedbackPage} from './feedback/FeedbackPage';
import { FollowUps } from './followups/FollowUps';

export function TasksPage(): JSX.Element {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const location = useLocation();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true);
      try {
        const fetchedTasks = await medplum.searchResources('Task', {});
        setTasks(fetchedTasks);
      } catch (err) {
        console.error('Failed to fetch tasks:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [medplum, location.state]);

  if (loading) {
    return <Loading />;
  }

  const getTaskLink = (task: Task): string => {
    if (task.focus) {
      const focus = task.focus;
      if (focus.reference && focus.reference.startsWith('Questionnaire/')) {
        const questionnaireId = focus.reference.split('/')[1];
        return `/forms/${questionnaireId}`;
      }
      return focus.reference || '#';
    }
    return '#';
  };

  return (
    <Document width={800}>
      <Title>Hi {profile?.name?.[0]?.given?.[0] || 'There'}</Title>
      <Text size="md" c="dimmed">Contact Ramona anytime via phone call or SMS at 505-4RAMONA</Text>
      <Divider my="xl" />
      <Stack>
        <Text size="xxl">Your Tasks</Text>
        {tasks.map((task) => (
          <Card
            key={task.id}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            component={Link}
            to={getTaskLink(task)}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Group justify="space-between" mt="md" mb="xs">
              <Text size="xl" fw={500}>{task.description}</Text>
              <Badge color={getStatusColor(task.status)} variant="light">
                {task.status}
              </Badge>
            </Group>
            <Text size="sm" c="dimmed">
              {task.note && task.note.length > 0 ? task.note[0].text : 'Text or call anytime to update'}
            </Text>
          </Card>
        ))}
      </Stack>
      <Divider my="md" />
      <FollowUps />
      <Divider my="md" />

      <Box mt="lg">
        <FeedbackPage />
      </Box>
    </Document>
  );
}

function getStatusColor(status: string | undefined): string {
  switch (status) {
    case 'ready':
      return 'blue';
    case 'in-progress':
      return 'yellow';
    case 'completed':
      return 'green';
    case 'cancelled':
      return 'red';
    default:
      return 'gray';
  }
}