import { AppShell, Avatar, Burger, Container, Drawer, Group, Menu, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMedplumProfile } from '@medplum/react';
import { IconChevronDown, IconLifebuoy, IconLogout, IconSettings, IconUserCircle } from '@tabler/icons-react';
import cx from 'clsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classes from './Header.module.css';


const navigation = [
  { name: 'Tasks', href: '/tasks' },
  { name: 'Health Record', href: '/health-record/vitals/blood-pressure' }
];

export function Header(): JSX.Element {
  const navigate = useNavigate();
  const profile = useMedplumProfile();
  const theme = useMantineTheme();
  const [opened, { toggle, close }] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);

  const menuItems = [
    { icon: <IconUserCircle size={16} color={theme.colors.red[6]} stroke={1.5} />, label: 'My Profile', onClick: () => navigate('/account/profile') },
    { icon: <IconLifebuoy size={16} color={theme.colors.orange[6]} stroke={1.5} />, label: 'Contact Support', onClick: () => window.open(`mailto:support@ramona.care?subject=Member Support ticket ${profile?.id}`, '_blank') },
    { icon: <IconLogout size={16} color={theme.colors.gray[6]} stroke={1.5} />, label: 'Sign Out', onClick: () => navigate('/signout') }
  ];

  return (
    <>
    <AppShell.Header>
      <Container>
        <div className={classes.inner}>
          <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
            <Text c="orange.8" tt="uppercase" size="xxl" fw={600} >RAMONA</Text>
          </UnstyledButton>
          <Group gap={5} className={classes.links}>
            {navigation.map((link) => (
              <Link key={link.name} to={link.href} className={classes.link}>
                {link.name}
              </Link>
            ))}
          </Group>
          <Menu
            width={260}
            shadow="xl"
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
          >
            <Menu.Target>
              <UnstyledButton className={cx(classes.user, { [classes.userActive]: userMenuOpened })}>
                <Group gap={7}>
                  <Avatar radius="xl" color="orange">
                    {profile?.name?.[0]?.given?.[0]?.charAt(0) ?? ''}
                    {profile?.name?.[0]?.family?.charAt(0) ?? ''}
                  </Avatar>
                  <IconChevronDown size={12} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<IconUserCircle size={16} color={theme.colors.red[6]} stroke={1.5} />}
                onClick={() => navigate('/account/profile')}
              >
                My Profile
              </Menu.Item>
              <Menu.Item
                leftSection={<IconLifebuoy size={16} color={theme.colors.blue[6]} stroke={1.5} />}
                onClick={() => window.open(`mailto:support@ramona.care?subject=Member Support ticket ${profile?.id}`, '_blank') }
              >
                Contact Support
              </Menu.Item>
              <Menu.Item
                leftSection={<IconLogout size={16} color={theme.colors.gray[6]} stroke={1.5} />}
                onClick={() => navigate('/signout')}
              >
                Sign Out
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
        </div>
      </Container>
    </AppShell.Header>
    <Drawer
        opened={opened}
        onClose={close}
        size="100%"
        padding="md"
        title={
          <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
            <Text c="orange.8" tt="uppercase" size="xxl" fw={600}>RAMONA</Text>
          </UnstyledButton>
        }
        zIndex={1000}
      >
        <div className={classes.mobileLinks}>
          {navigation.map((link) => (
            <UnstyledButton
              key={link.name}
              className={classes.mobileLink}
              onClick={() => {
                navigate(link.href);
                close();
              }}
            >
              {link.name}
            </UnstyledButton>
          ))}
          <div className={classes.mobileDivider} />
          {menuItems.map((item, index) => (
            <UnstyledButton
              key={index}
              className={classes.mobileLink}
              onClick={() => {
                item.onClick();
                close();
              }}
            >
              <Group>
                {item.icon}
                <span>{item.label}</span>
              </Group>
            </UnstyledButton>
          ))}
        </div>
      </Drawer>

    </>
  );
}
