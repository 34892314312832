import { BackgroundImage, Box, Paper, SimpleGrid } from '@mantine/core';
import { SignInForm, useMedplumProfile } from '@medplum/react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig, isRegisterEnabled } from '../../config';
import { useMatomo } from '@/common/useMatomo';

export function SignInPage(): JSX.Element {
  const profile = useMedplumProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();
  const { trackPageView, trackEvent } = useMatomo();

  useEffect(() => {
    trackPageView('/signin');
  }, []);

  const navigateToNext = useCallback(() => {
    // only redirect to next if it is a pathname to avoid redirecting
    // to a maliciously crafted URL, e.g. /signin?next=https%3A%2F%2Fevil.com
    const nextUrl = searchParams.get('next');
    navigate(nextUrl?.startsWith('/') ? nextUrl : '/');
  }, [searchParams, navigate]);

  useEffect(() => {
    if (profile && searchParams.has('next')) {
      navigateToNext();
    }
    trackEvent('User', 'Sign In', 'Successful');
  }, [profile, searchParams, navigateToNext]);

  return (
    <Paper>
      <Box pt={100} pb={200}>
        <SignInForm
          clientId={config.clientId}
          projectId={config.projectId}
          googleClientId={config.googleClientId}
          onSuccess={() => navigateToNext()}
          onForgotPassword={() => navigate('/resetpassword')}
          onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
        >
          <h2>Sign in to Ramona</h2>
        </SignInForm>
      </Box>
      
    </Paper>
  );
}
