import { Anchor, Button, Group, Stack, Text, TextInput, Title } from '@mantine/core';
import { normalizeOperationOutcome } from '@medplum/core';
import { OperationOutcome } from '@medplum/fhirtypes';
import {
  Document,
  Form,
  getErrorsForInput,
  getIssuesForExpression,
  getRecaptcha,
  initRecaptcha,
  OperationOutcomeAlert,
  useMedplum,
} from '@medplum/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from '../../config';
import { LogoSquare } from '../../components/LogoSquare'

export function ResetPasswordPage(): JSX.Element {
  const navigate = useNavigate();
  const medplum = useMedplum();
  const config = getConfig();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [success, setSuccess] = useState(false);
  const recaptchaSiteKey = getConfig().recaptchaSiteKey;

  useEffect(() => {
    if (recaptchaSiteKey) {
      initRecaptcha(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey]);

  return (
    <Document width={450}>
      <Form
        onSubmit={async (formData: Record<string, string>) => {
          if (isSubmitting) return;
          setIsSubmitting(true);

          try {
            let recaptchaToken = '';
            if (recaptchaSiteKey) {
              recaptchaToken = await getRecaptcha(recaptchaSiteKey);
            }

            await medplum.post('auth/resetpassword', { ...formData, recaptchaToken });
            setSuccess(true);
          } catch (err) {
            setOutcome(normalizeOperationOutcome(err));
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        <Stack gap="lg" mb="xl" align="center">
          <LogoSquare size={32} />
          <Title>Password Reset</Title>
        </Stack>
        <Stack gap="xl">
          <OperationOutcomeAlert issues={getIssuesForExpression(outcome, undefined)} />
          {!success && (
            <>
              <TextInput
                name="email"
                type="email"
                label="Email"
                required={true}
                autoFocus={true}
                error={getErrorsForInput(outcome, 'email')}
              />

              <Group justify="space-between"  wrap="nowrap">
              <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
                {isSubmitting ? 'Resetting...' : 'Reset Password'}
              </Button>

              </Group>
              <input type="hidden" name="projectId" value={config.projectId} />
            </>
          )}
          {success && <div><p>If the account exists on our system, a password reset email will be sent.</p><p><a href="https://my.ramona.care/signin">Sign In</a></p></div>}
        </Stack>
        <Text mt="md" c="dimmed">Contact <Anchor c={"dimmed"} href="mailto:support@ramona.care">support@ramona.care</Anchor> with any account issues.</Text>
      </Form>
    </Document>
  );
}
