import { 
  Container, Paper, Title, Text, Button, Stack, Group, ThemeIcon, 
  Card, Box, Badge, List, Grid,
  Divider,
  Loader
} from '@mantine/core';
import { Patient, Goal, Task, Observation, Condition, Account } from '@medplum/fhirtypes';
import { useMedplumProfile, useMedplum } from '@medplum/react';
import { IconChartBar, IconCheck, IconClock, IconStar, IconStarFilled, IconStarOff, IconTarget } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getConfig } from '@/config'


const SelectPlanPage = () => {
  const profile = useMedplumProfile() as Patient;
  const firstName = profile.name?.find(n => n.use === 'nickname')?.given?.[0] || 
                 profile.name?.[0]?.given?.[0] || 
                 '';
  const medplum = useMedplum();
  const config = getConfig();
  const environment = config.environment || 'staging';
  const email = profile.telecom?.find(t => t.system === 'email')?.value || '';
  const [goal, setGoal] = useState<Goal>();
  const [nextStep, setNextStep] = useState<Task>();
  const [recommendedPlan, setRecommendedPlan] = useState<string>('vitals-plus');
  const [showAllPlans, setShowAllPlans] = useState(false);
  const [accountId, setAccountId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 15;
  const RETRY_DELAY = 2000;

    // Fetch the user's active goal and next step
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          // Get most recent active goal
          const goals = await medplum.searchResources('Goal', {
            patient: profile.id
          });

          
          if (goals.length > 0) {
            setGoal(goals[0]);
            
            // Get associated task (next step)
            const tasks = await medplum.searchResources('Task', {
              'based-on': `Goal/${goals[0].id}`,
              status: 'requested',
              _count: '1'
            });
            
            if (tasks.length > 0) {
              setNextStep(tasks[0]);
            }

            if ((goals.length === 0 || tasks.length === 0 )&& retryCount < MAX_RETRIES) {
              // If no goals or tasks found, retry after delay
              setTimeout(() => {
                setRetryCount(prev => prev + 1);
              }, RETRY_DELAY);
              medplum.invalidateAll();
              return;
            }
          }
      
          // Fetch motivation level
          const motivationObs = await medplum.searchResources('Observation', {
            patient: profile.id,
            code: 'http://ramona.care|motivation-level',
            _sort: '-date',
            _count: '1'
          });

          // Fetch conditions count
          const conditions = await medplum.searchResources('Condition', {
            patient: profile.id,
            'clinical-status': 'active'
          });

          // Fetch BP medication status
          const bpMedicationObs = await medplum.searchResources('Observation', {
            patient: profile.id,
            code: 'http://ramona.care|bp-medication-status',
            _sort: '-date',
            _count: '1'
          });

           // Fetch Account linked to Patient
          const accounts = await medplum.searchResources('Account', {
            subject: `Patient/${profile.id}`,
            status: 'active',
            _count: '1'
          });

          if (accounts.length > 0) {
            setAccountId((accounts[0] as Account).id || '');
          }

          // Determine recommended plan
          const motivationScore = (motivationObs[0] as Observation)?.valueInteger || 0;
          const conditionsCount = conditions.length;
          const takingBPMedication = (bpMedicationObs[0] as Observation)?.valueString === 'Yes';


          if (motivationScore >= 9 && conditionsCount === 0 && !takingBPMedication) {
            setRecommendedPlan('essential');
          } else if (
            conditionsCount >= 3 ||
            (motivationScore <= 3 && takingBPMedication) ||
            (conditionsCount >= 2 && takingBPMedication)
          ) {
            setRecommendedPlan('concierge');
          } else {
            setRecommendedPlan('vitals-plus');
          }
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          if (retryCount < MAX_RETRIES) {
            // If error occurs, retry after delay
            setTimeout(() => {
              setRetryCount(prev => prev + 1);
            }, RETRY_DELAY);
            medplum.invalidateAll();
            return;
          }
        } finally {
          if (retryCount >= MAX_RETRIES) {
            setIsLoading(false);
          }
        }
      };
  
    fetchData();
    }, [medplum, profile.id, retryCount]);

    // Add error state handling if max retries reached
    if (!isLoading && !goal && retryCount >= MAX_RETRIES) {
      return (
        <Container size="md">
          <Paper shadow="md" px="xl" mt="lg">
            <Stack gap="xl" align="center" py="xl">
              <Text color="red">
                Unable to load your personalized recommendations. Please try refreshing the page.
              </Text>
            </Stack>
          </Paper>
        </Container>
      );
    }

    if (isLoading) {
      return (
        <Container size="md">
          <Paper shadow="md" px="xl" mt="lg">
            <Stack gap="xl" align="center" py="xl">
              <Text>Loading your personalized plan recommendations...</Text>
              <Loader size={45} />
            </Stack>
          </Paper>
        </Container>
      );
    }


    // Update the plans array with dynamic URLs including accountId based on environment
    const plansWithMetadata = plans.map(plan => ({
      ...plan,
      url: `${stripeUrls[environment as 'production' | 'staging'][plan.id as keyof typeof stripeUrls.production]}?client_reference_id=${accountId}&prefilled_email=${email}`
    }));

    const scrollToPlan = (planId: string) => {
      setTimeout(() => {
        const planElement = document.getElementById(`plan-${planId}`);
        if (planElement) {
          planElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100); // Small delay to ensure DOM is updated
    };

    const renderRecommendedPlan = () => {
      const plan = plansWithMetadata.find(p => p.id === recommendedPlan);
      if (!plan) return null;
    
      return (
        <Stack gap="md">
          {/* Main Recommendation Card */}
          <Box pos="relative">
        <Badge 
          color="blue"
          size="lg"
          styles={{
            root: {
              position: 'absolute', 
              top: '-12px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1
            }
          }}
        >
          Recommended Plan
        </Badge>
        <Card shadow="sm" padding="lg" radius="md" withBorder 
          styles={(theme) => ({
            root: {
              borderColor: theme.colors.blue[6],
              borderWidth: '2px'
            }
          })}
        >
          <Stack gap="md">
            <Group justify='space-between'>
              <div>
                <Title order={3}>{plan.name}</Title>
                <Text size="sm">{plan.description}</Text>
              </div>
              <div>
                <Text fw={700} size="xl">{plan.price}</Text>
                {plan.annualPrice && (
                  <Text size="sm" color="dimmed">{plan.annualPrice}</Text>
                )}
              </div>
            </Group>
              
              {plan.highlightedFeature && (
                <Box bg="blue.0" p="md" style={{ borderRadius: '8px' }}>
                  <Group align="flex-start" gap="xs">
                    <IconStar size="1rem" color='orange' style={{ marginTop: '4px' }}/>
                    <div>
                      <Text fw={700}>{plan.highlightedFeature.title}</Text>
                      <Text size="sm">{plan.highlightedFeature.description}</Text>
                    </div>
                  </Group>
                </Box>
              )}
    
              <List
                spacing="sm"
                size="sm"
                styles={{
                  itemWrapper: {
                    alignItems: 'flex-start'
                  },
                  itemIcon: {
                    marginTop: '4px'
                  }
                }}
                icon={
                  <ThemeIcon color="teal" size={24} radius="xl">
                    <IconCheck size="1rem" />
                  </ThemeIcon>
                }
              >
                {plan.features.map((feature, index) => (
                  <List.Item key={index}>
                    <Text fw={700}>{feature.title}</Text>
                    <Text>{feature.description}</Text>
                  </List.Item>
                ))}
              </List>
              
              <Button 
                color="blue" 
                fullWidth 
                component={Link} 
                to={plan.url}
              >
                {plan.buttonText}
              </Button>
              
              <Text size="sm" ta="center">{plan.trialText}</Text>
            </Stack>
          </Card>
          </Box>
    
          <Divider my="sm" />
    
          <Text fw={700} ta="center">Alternative Plans</Text>
          <Group 
        grow 
        align="stretch" 
        style={{ 
          flexWrap: 'wrap',
          justifyContent: 'center' // This centers the cards
        }}
      >
        {plansWithMetadata.filter(p => p.id !== recommendedPlan).map(altPlan => (
          <Card 
            key={altPlan.id} 
            shadow="sm" 
            padding="sm" 
            radius="md" 
            withBorder
            style={{ 
              flex: '1 1 250px',
              minWidth: '250px'
            }}
          >
                <Stack justify="space-between" h="100%">
                  <div>
                    <Text fw={700}>{altPlan.name}</Text>
                    <Text size="sm">{altPlan.price}</Text>
                    <Text size="xs">{altPlan.description}</Text>
                  </div>
                  <Button 
                    variant="light" 
                    fullWidth 
                    mt="sm" 
                    onClick={() => {
                      setShowAllPlans(true);
                      scrollToPlan(altPlan.id);
                    } }
                  >
                    Compare Plans
                  </Button>
                </Stack>
              </Card>
            ))}
          </Group>
        </Stack>
      );
    };

    const renderAllPlans = () => {

      return (
        <Grid>
          {plansWithMetadata.map((plan) => (
            <Grid.Col span={{ base: 12, md:4}} key={plan.id} id={`plan-${plan.id}`}>
              <Box pos="relative">
                {plan.id === recommendedPlan && (
                  <Badge 
                    color="blue"
                    size="lg"
                    styles={{
                      root: {
                        position: 'absolute',
                        top: '-12px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1
                      }
                    }}
                  >
                    Recommended
                  </Badge>
                )}
                <Card 
                  shadow="sm" 
                  padding="lg" 
                  radius="md" 
                  withBorder
                  styles={(theme) => ({
                    root: {
                      borderColor: plan.id === recommendedPlan ? theme.colors.blue[6] : undefined,
                      borderWidth: plan.id === recommendedPlan ? '2px' : undefined,
                      height: '100%'
                    }
                  })}
                >
                  <Stack gap="md">
                    <Stack gap={4}>
                      <Group>
                        <Title order={4}>{plan.name}</Title>
                      </Group>
    
                      <Text fw={700} size="xl">{plan.price}</Text>
                      {plan.annualPrice && (
                        <Text size="sm" c="dimmed">{plan.annualPrice}</Text>
                      )}
                    </Stack>

                    <Text size="sm">{plan.description}</Text>

                    <Button 
                      color="blue" 
                      fullWidth 
                      component={Link} 
                      to={plan.url}
                      variant={plan.id === recommendedPlan ? 'filled' : 'light'}
                      mt="auto"
                    >
                      {plan.buttonText}
                    </Button>
    
                    {plan.highlightedFeature && (
                      <Box bg="blue.0" p="sm" style={{ borderRadius: '8px' }}>
                        <Text>{plan.highlightedFeature && (
                          
                            <IconStar size="1rem" color='orange' style={{ marginTop: '2px', marginRight:'0.25rem' }}/>
                          
                        )} {plan.highlightedFeature.title}</Text>
                      </Box>
                    )}
    
                    <List
                      spacing="sm"
                      size="sm"
                      styles={{
                        itemWrapper: {
                          alignItems: 'flex-start'
                        },
                        itemIcon: {
                          marginTop: '4px'
                        }
                      }}
                      icon={
                        <ThemeIcon color="teal" size={24} radius="xl">
                          <IconCheck size="1rem" />
                        </ThemeIcon>
                      }
                    >
                      {plan.comparisonFeatures.map((feature, index) => (
                        <List.Item key={index}>
                          <Text >{feature.title}</Text>
                        </List.Item>
                      ))}
                    </List>
    
                    <Text size="sm" ta="center" c="dimmed">
                      {plan.trialText}
                    </Text>
                  </Stack>
                </Card>
              </Box>
            </Grid.Col>
          ))}
        </Grid>
      );
    };

    return (
      <Container size="md">
        <Paper shadow="md" px="xl" mt="lg">
          <Stack gap="xl">
            {/* Personalized Header */}
            <Stack gap="md" ta="center">
                <Box bg="orange.0" p="lg" style={{ borderRadius: '8px' }}>
                  <Stack gap="md">
                    <Group align="center" justify="center">
                      <Text size="xl" fw={600}>Congratulations {firstName} on taking charge of your health! 🎉</Text>
                    </Group>
                    
                    <Text size="lg" ta="center">
                    Now, let’s set you up for success with a coaching plan based on your stated heart health goals and health profile.
                    </Text>
                  </Stack>
                </Box>
            </Stack>
 
            {showAllPlans ? renderAllPlans() : renderRecommendedPlan()}

            {/* Footer Text */}
            <Text ta="center" size="sm" c="dimmed">
              Essential plan includes 10-day free trial. Vitals Plus and Concierge Care plans include 30-day satisfaction guarantee. 
              If you're not satisfied, receive a full refund minus $90 device cost. See our <Text component="a" inherit c="dimmed" href="https://www.ramona.care/refund-policy/" target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>Refund Policy</Text> for complete details.
            </Text>
          </Stack>
        </Paper>
      </Container>
    );
};

const stripeUrls = {
  production: {
    essential: 'https://buy.stripe.com/6oEdTZ20W2w8a4M145',
    'vitals-plus': 'https://buy.stripe.com/5kA039axseeQ2CkfZ2',
    concierge:'https://buy.stripe.com/bIYeY3gVQeeQb8Q149',
  },
  staging: {
    essential: 'https://buy.stripe.com/test_9AQ5o90Cb0hz6zeeUX',
    'vitals-plus': 'https://buy.stripe.com/test_5kA8Al4Src0h7DibIK',
    concierge:'https://buy.stripe.com/test_6oE9Ep2Kj7K1f5K3cd',
  }
}

const plans = [
    {
      id: 'essential',
      name: 'Essential',
      price: '$29/month',
      annualPrice: '$29/mo billed monthly',
      description: 'Our leanest plan',
      buttonText: 'Start Free Trial',
      url: 'https://buy.stripe.com/6oEdTZ20W2w8a4M145',
      trialText: 'The Essential plan includes a 10-day free trial. No charge until day 10, cancel anytime before then.',
      features: [
        { title: 'Essential AI Coaching Package', description: '100 coaching messages and 1 hour of coaching calls for deeper guidance every month' },
        { title: 'Daily Accountability Check-ins', description: 'One strategic wellness prompt daily to help you stay on track' },
        { title: '24/7 personalized AI guidance', description: 'Whenever you need it' },
        { title: 'Custom wellness strategy', description: 'For your unique goals, needs, and preferences' }
      ],
      comparisonFeatures: [
        { title: 'Personalized Wellness Strategies', description: 'Custom strategies tailored to your needs' },
        { title: 'Regular Progress Assessments', description: 'Track and measure your health journey' },
        { title: 'Custom Goal-Setting and Tracking', description: 'Set and monitor personalized health goals' },
        { title: '24/7 AI-Powered Virtual Coaching', description: 'Always available support and guidance' },
        { title: '100 SMS Coaching Messages/Month', description: 'Direct communication with your AI coach' },
        { title: '1 Hour Voice Coaching/Month', description: 'In-depth coaching conversations' },
        { title: '1 Daily Wellness Prompt', description: 'Daily accountability and motivation' }
      ]
    },
  {
    id: 'vitals-plus',
    name: 'Vitals Plus',
    price: '$59/month',
    annualPrice: '$708/year billed annually',
    description: 'Our most popular plan',
    buttonText: 'Get Vitals Plus Plan',
    url: 'https://buy.stripe.com/5kA039axseeQ2CkfZ2',
    trialText: "The Vitals Plus plan includes a 30-day satisfaction guarantee. If you're not satisfied, receive a full refund minus $90 device cost.",
    highlightedFeature: {
      title: 'Included: 4G-Connected BP Monitor ($90 value)',
      description: 'Automatic data syncing for effortless tracking'
    },
    features: [
      { title: 'Plus AI Coaching Package', description: '200 coaching messages and 2 hours of coaching calls for deeper guidance every month' },
      { title: 'Structured Success Check-ins', description: 'Up to 3 strategic wellness prompts throughout your day for consistent support' },
      { title: 'Personalized Wellness Strategy', description: 'Custom health plan with ongoing optimization based on your data' },
      { title: 'Monthly Progress Report', description: 'Detailed trend analysis to celebrate wins and refine your strategy' }
    ],
    comparisonFeatures: [
      { title: 'Everything in Essential, plus:', description: '' },
      { title: '4G-Connected Blood Pressure Monitor', description: 'Seamless BP tracking and monitoring' },
      { title: '200 SMS Coaching Messages/Month', description: 'Enhanced communication with your AI coach' },
      { title: '2 Hours Voice Coaching/Month', description: 'Extended coaching conversations' },
      { title: 'Up to 3 Daily Wellness Prompts', description: 'Increased accountability throughout your day' },
      { title: 'Trend Analysis Reports', description: 'Detailed insights into your progress' }
    ]
  },
  {
    id: 'concierge',
    name: 'Concierge Care',
    price: '$129/month',
    annualPrice: '$1548/year billed annually',
    description: 'Our most comprehensive plan',
    buttonText: 'Get Concierge Care Plan',
    url: 'https://buy.stripe.com/bIYeY3gVQeeQb8Q149',
    trialText: "The Concierge plan includes a 30-day satisfaction guarantee. If you're not satisfied, receive a full refund minus $90 device cost.",
    highlightedFeature: {
      title: 'Included: 4G-Connected BP Monitor ($90 value)',
      description: 'Automatic data syncing with priority technical support'
    },
    features: [
      { title: 'Concierge AI Coaching Package', description: 'Unlimited coaching messages and 3 hours of coaching calls for deeper guidance every month' },
      { title: 'Comprehensive Check-ins', description: 'Up to 5 strategic wellness prompts throughout your day for maximum support and accountability' },
      { title: 'In-Depth Health Strategy', description: 'Detailed analysis of your health patterns with continuous plan optimization and expert insights' },
      { title: 'Early Access Benefits', description: 'First access to new features and exclusive wellness content' }
    ],
    comparisonFeatures: [
      { title: 'Everything in Vitals Plus, plus:', description: '' },
      { title: 'Unlimited SMS Messages', description: 'No limits on AI coach communication' },
      { title: '3 Hours Voice Coaching/Month', description: 'Maximum coaching conversation time' },
      { title: 'Up to 5 Flexible Daily Prompts', description: 'Comprehensive daily support and accountability' },
      { title: 'Early Access to New Features', description: 'Be first to try new capabilities' }
    ]
  }
];

export default SelectPlanPage;
