import { AppShell, Container, UnstyledButton, Group, Text, ActionIcon } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import classes from './Header.module.css';

interface BackHeaderProps {
  backTo?: string;
}

export function BackHeader({ backTo = '/' }: BackHeaderProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <AppShell.Header>
      <Container>
        <div className={classes.inner}>
          <Group>
            <ActionIcon
              onClick={() => navigate(backTo)}
              size="lg"
              variant="subtle"
              color="gray"
              title="Go back"
            >
              <IconArrowLeft size={24} />
            </ActionIcon>
            <UnstyledButton className={classes.logoButton} onClick={() => navigate('/')}>
              <Text c="orange.8" tt="uppercase" size="xxl" fw={600}>RAMONA</Text>
            </UnstyledButton>
          </Group>
        </div>
      </Container>
    </AppShell.Header>
  );
}