import { getConfig } from '@/config';
import { eventNames } from 'process';
import { useCallback, useEffect } from 'react';

declare global {
  interface Window {
    _mtm: any[];
  }
}

export function initMatomo(): void {
  const config = getConfig();
  const matomoTagUrl = config.matomoTagUrl;

  if (!matomoTagUrl) {
    console.warn('Matomo URL not configured');
    return;
  }

  window._mtm = window._mtm || [];
  window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});

  const script = document.createElement('script');
  script.async = true;
  script.src = matomoTagUrl;
  
  const firstScript = document.getElementsByTagName('script')[0];
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(script, firstScript);
  }
}

export function useMatomo() {
  const isValidParameter = (param: string | undefined): boolean => {
    if (!param) return false;
    return param.trim().length > 0;
  };

  const trackPageView = useCallback((path: string) => {
    if (!window._mtm) return;
    
    try {
      window._mtm.push({
        event: 'mtm.PageView',
        pageTitle: document.title,
        page: path,
        url: path,
        generationTime: new Date().getTime()
      });
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  }, []);

  const trackEvent = (category: string, action: string, name?: string, value?: string) => {
    if (!window._mtm) return;

    try {
      // Ensure category and action are strings and not empty
      const eventCategory = String(category || '').trim();
      const eventAction = String(action || '').trim();

      if (!eventCategory || !eventAction) {
        console.warn('Event tracking requires non-empty category and action');
        return;
      }

      // Match Matomo's expected event structure exactly
      const eventData = {
        eventCategory: eventCategory,  // Event Category
        eventAction: eventAction,    // Event Action
        eventName: name ? String(name).trim() : undefined,  // Event Name (optional)
        eventValue: value,          // Event Value (optional)
      };

      window._mtm.push(eventData);
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  };

  const trackFormEvent = (category: string, action: string, name?: string, value?: string) => {
    if (!window._mtm) return;

    try {

      if (!category || !action) {
        console.warn('Form event tracking requires non-empty category and action');
        return;
      }

      // Match Matomo's expected form event structure
      const eventData = {
        eventCategory: category,
        eventAction: action,
        eventName: name,
        eventValue: value,
        event: 'Form Navigation',
        'formBlock': name || ''
      };

      const pushResult = window._mtm.push(eventData);

    } catch (error) {
      console.error('Error tracking form event:', error);
    }
  };

  return { trackPageView, trackEvent, trackFormEvent };
}
