import { MantineColorsTuple, MantineProvider, createTheme } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import '@medplum/react/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { getConfig } from './config';
import { MatomoRouteTracker } from './common/MatomoRouteTracker';
import { initMatomo } from './common/useMatomo';

// Initialize Matomo as early as possible
initMatomo();

const config = getConfig();

const medplum = new MedplumClient({
  // To run Member App locally, you can set the baseURL in this constructor
  baseUrl: config.baseUrl,
  clientId: config.clientId,
  onUnauthenticated: () => {
    if (window.location.pathname !== '/signin' && window.location.pathname !== '/oauth') {
      window.location.href = '/signin?next=' + encodeURIComponent(window.location.pathname + window.location.search);
    }
  },
});

const orange: MantineColorsTuple = [
  "#fff4e5",
  "#ffe8d0",
  "#fdce9f",
  "#fcb36a",
  "#fb9c3e",
  "#fb8e23",
  "#fb8615",
  "#e07309",
  "#c76602",
  "#ae5600"
];

const theme = createTheme({
  primaryColor: 'orange',
  colors: {
    orange,
  },
  primaryShade: 8,
  white: '#F9F7F3',
  fontFamily: 'Urbanist, sans-serif',
  fontSizes: {
    xs: '0.6875rem',
    sm: '0.875rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.125rem',
    xxl: '1.4rem'
  },
  components: {
    Container: {
      defaultProps: {
        size: 1200,
      },
    },
  },
  /** Controls various styles of h1-h6 elements, used in TypographyStylesProvider and Title components */
  headings: {
    fontFamily: 'Urbanist, sans-serif'
  }
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <MedplumProvider medplum={medplum}>
        <MantineProvider theme={theme}>
          <MatomoRouteTracker />
          <App />
        </MantineProvider>
      </MedplumProvider>
    </BrowserRouter>
  </StrictMode>
);
