import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from './useMatomo';

export function MatomoRouteTracker() {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    // Track the page view whenever location changes
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]); // Include both location and trackPageView in dependencies

  return null;
}
